import { Skeleton } from "@mantine/core";

export function SkeletonLoader( props ){
  return(
    props.visible ?
    <Skeleton height={props.height} width={props.width} mb={props.mb} mt={props.mt} my={props.my} mx={props.mx} radius="md"/>
    :
    <>
      {props.children}
    </>
  )
}