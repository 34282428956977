
import { Button, Text } from "@mantine/core"
import { GitCompare, Notes, ShoppingCart } from "tabler-icons-react"
import { TalantFilter } from "./TalantFilter"
import { UserFlyer } from "./UserFlyer"

export const AllUsers = ({ props }) => {

  const { program, reFetch, reportData, setReportData, selectedAccounts, setSelectedAccounts, setWishlistedUsers } = props

  function addToWishlist(){
    setWishlistedUsers(prev => [...prev, ...selectedAccounts])
    setSelectedAccounts([])
  }

  return (
    <>

      <TalantFilter props={{ program, reFetch, setReportData }}/>
      <br />

      <div className="header">
          <div className="selected">
            <div className="selectOrNot">
              <Text size={20} weight={600}>Selected accounts: {selectedAccounts?.length}</Text>
              <Button disabled={selectedAccounts.length === 0} onClick={() => setSelectedAccounts([])} color="gray.6">Deselect all</Button>
            </div>
            <div className="buttonsManageSelected">
              <Button onClick={() => addToWishlist()} disabled={selectedAccounts.length === 0} color={"gray.7"} leftIcon={<Notes/>}>Add to wishlist: {selectedAccounts?.length}</Button>
              <Button disabled={selectedAccounts.length < 2} color={"gray.7"} leftIcon={<GitCompare/>}>Compare skills</Button>
              <Button disabled={selectedAccounts.length === 0} color={"teal"} leftIcon={<ShoppingCart/>}>Purchase now: {selectedAccounts?.length}</Button>
            </div>
          </div>

          {/* <div className="selectionFilter">
            <Text size={18} weight={600} className="identificationTitle">Filter</Text>
            <div className="boxes">
              <Checkbox color={"gray"} label="Wishlisted hidden" size="md"/>
              <Checkbox color={"gray"} label="Purchased hidden" size="md"/>
            </div>
          </div> */}
        </div>

        <div className="users">
          <div className="reportData">

            <div className="reportTable">

              <div className="thead">
                <Text size={18} weight={600} className="identificationTitle">User profile</Text>
                <Text size={18} weight={600}>University</Text>
                <Text size={18} weight={600}>Location</Text>
                <Text size={18} weight={600}>Contact Info</Text>
              </div>

              <div className="tbody">
                {
                  reportData?.results && reportData?.results.map((data, index) => <UserFlyer key={data?.id} props={{ data, index, selectedAccounts, setSelectedAccounts }}/>)
                }
              </div>

            </div>

          </div>
        </div>
    </>
  )
}
