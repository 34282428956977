import styled from "styled-components"
import { AboutProgram } from "./AboutProgram"
import { ProgramSpecs } from "./ProgramSpecs"

export function ProgramInfo({ props }){

  const { entryData, user, type } = props;


  return(
    <ProgramInfoStyle>

      {/* <CurrentLocation props={{ entryData, type }} /> */}

      <div className="wrapper">
        <AboutProgram props={{ entryData }} />
        <div className="right">
          <ProgramSpecs props={{ entryData }} />
          {/* <Payment props={{ user, entryData, type }} /> */}
        </div>
      </div>

    </ProgramInfoStyle>
  )
}

const ProgramInfoStyle = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 0.5rem auto 5rem auto;
  .wrapper{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 1.5rem;
    margin: .5rem 0;
  }
  @media screen and (max-width:1024px){
    width: 90%;
    margin: 0 auto;
    padding: 0rem;
    .wrapper{
      grid-template-columns: 1fr;
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
  }
`