import { Center, Container, Image, Text } from "@mantine/core";
import tabs from "../Assets/Images/figures2.png";

export const EmptyDash = () => {
  return (
    <Center mt={150}>
      <Container>
        <Center>
          <Image src={tabs} width="300px" />
        </Center>
        <Text color={"dimmed"} mt={30} weight={500} size={30}>
          Choose one of the tabs on the left to start
        </Text>
      </Container>
    </Center>
  );
};
