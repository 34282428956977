import React, { useState, useContext } from "react";
import parse from "html-react-parser";
import {
  Button,
  Grid,
  Image,
  Modal,
  Textarea,
  TextInput,
  FileInput,
  MultiSelect,
} from "@mantine/core";
import { Upload } from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";
import RichTextEditor from "@mantine/rte";
import { useQuery } from "@tanstack/react-query";

const Modals = ({
  showEditModal,
  showRemoveModal,
  showDetailsModal,
  setShowEditModal,
  setShowRemoveModal,
  setShowDetailsModal,
  item,
  itemId,
  values,
  setValues,
  user,
}) => {
  const deleteMethod = async (sentId) => {
    await axios.delete(`/hackathon/edit/${sentId}`);
    setShowRemoveModal(false);
    //  getData();
  };

  const { data: allUsers } = useQuery(
    ["all users"],
    async () => await axios.get(`user/all_users_list`).then((res) => res.data)
  );

  const submitAssignment = async (e) => {
    console.log(values);
    const { title, image, description, start_date, end_date, mentors } = values;
    e.preventDefault();

    const formX = new FormData();
    formX.append("title", title);
    formX.append("description", description);
    formX.append("price", 0);
    formX.append("start_date", start_date);
    formX.append("end_date", end_date);
    formX.append("deadline", null);
    formX.append("video", "1");
    formX.append("promocode", 21);
    formX.append("duration", 1);
    formX.append("language", 2);
    if (values.image !== item.image) {
      formX.append("image", image);
    }
    formX.append("user", user?.id);
    mentors.forEach((mentor) => {
      if (typeof mentor === "object") {
        formX.append("mentors", mentor.id);
      } else {
        formX.append("mentors", mentor);
      }
    });

    const response = await axios.patch(`/hackathon/edit/${values.id}/`, formX);
    setShowEditModal(false);
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <>
      <Modal
        fullScreen
        opened={item?.id === itemId && showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        title="Details of hackathon"
      >
        <HackathonMain>
          <div className="title">
            <div className="text">
              <Link to="#">{item?.title}</Link>
              <span>Hackhaton event</span>
            </div>
          </div>
          <div className="info">
            <Image
              width={"466px"}
              height={"480px"}
              radius="md"
              src={item?.image}
              alt="Random image"
              withPlaceholder
            />
            <div className="about">
              <div className="text">
                <h1>About</h1>
                <p>{item?.description && parse(item?.description)}</p>
              </div>
              <div className="rules">
                <div className="badge">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 15.0005V5.50049C6 5.26479 6 5.14693 6.07322 5.07371C6.14645 5.00049 6.2643 5.00049 6.5 5.00049H16.7929C17.3066 5.00049 17.5635 5.00049 17.6274 5.15482C17.6913 5.30915 17.5097 5.49078 17.1464 5.85404L13.2828 9.71765C13.1495 9.85098 13.0828 9.91765 13.0828 10.0005C13.0828 10.0833 13.1495 10.15 13.2828 10.2833L17.1464 14.1469C17.5097 14.5102 17.6913 14.6918 17.6274 14.8462C17.5635 15.0005 17.3066 15.0005 16.7929 15.0005H6ZM6 15.0005V19.0005"
                      stroke="#484848"
                      stroke-linecap="round"
                    />
                  </svg>
                  <p>
                    {`${new Date(
                      new Date(item?.start_date).setHours(
                        new Date(item?.start_date).getHours() + 4
                      )
                    ).toISOString()}`.substring(0, 10)}{" "}
                    -{" "}
                    {`${new Date(
                      new Date(item?.end_date).setHours(
                        new Date(item?.start_date).getHours() + 4
                      )
                    ).toISOString()}`.substring(0, 10)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </HackathonMain>
      </Modal>
      <Modal
        fullScreen
        opened={item.id === itemId && showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Edit hackathon"
      >
        <form onSubmit={submitAssignment}>
          <Grid>
            <Grid.Col span={12}>
              <MultiSelect
                placeholder="Select mentors"
                searchable
                clearable
                data={
                  allUsers?.map((user) => ({
                    value: user?.id,
                    label: user?.email,
                  })) ?? []
                }
                defaultValue={values?.mentors?.map((user) => user?.id) || []}
                onChange={(value) => setValues({ ...values, mentors: value })}
                required
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                placeholder="Title"
                defaultValue={values?.title}
                onChange={(e) =>
                  setValues({ ...values, title: e.target.value })
                }
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <DatePicker
                valueFormat="YYYY MMM DD"
                placeholder="Start date"
                defaultValue={new Date(values?.start_date)}
                maw={400}
                onChange={(e) =>
                  setValues({
                    ...values,
                    start_date: new Date(e).toISOString().replace(/\.000/, ""),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <DatePicker
                valueFormat="YYYY MMM DD"
                defaultValue={new Date(values?.end_date)}
                placeholder="End date"
                maw={400}
                onChange={(e) =>
                  setValues({
                    ...values,
                    end_date: new Date(e).toISOString().replace(/\.000/, ""),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {/* <Textarea
                autosize
                minRows={8}
                placeholder="Description"
                defaultValue={values.description}
                onChange={(e) => {
                  setValues({ ...values, description: e.target.value });
                }}
              /> */}
              <RichTextEditor
                value={values.description}
                onChange={(e) => {
                  console.log(e);
                  setValues({ ...values, description: e });
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <FileInput
                //   placeholder={values.image.name}
                icon={<Upload size="14px" />}
                onChange={(e) => {
                  setValues({ ...values, image: e });
                  console.log(getBase64(values.image));
                }}
              />
              <span style={{ color: "red" }}>
                Şəkli dəyişmək istəyirsinizsə zəhmət olmasa düzgün görüntü üçün
                1080x1080 ölçüdə olan şəkil yükləyin.
              </span>

              {typeof values?.image == typeof "values?.image" && (
                <img
                  src={values.image}
                  alt=""
                  width={800}
                  height={700}
                  style={{ marginTop: "20px" }}
                />
              )}
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                fullWidth
                style={{ backgroundColor: "#333" }}
                type="submit"
              >
                Save changes
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
      <Modal
        opened={item.id === itemId && showRemoveModal}
        style={{ marginTop: "250px" }}
        onClose={() => setShowRemoveModal(false)}
        title="Remove hackathon"
      >
        <p>Are you sure to delete this hackathon?</p>
        <div style={{ display: "flex", gap: "30px" }}>
          <Button onClick={() => setShowRemoveModal(false)} color="gray">
            Cancel
          </Button>
          <Button onClick={() => deleteMethod(item.id)} color="red">
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

const HackathonMain = styled.div`
  .title {
    display: flex;

    .text {
      margin-left: 16px;

      a {
        color: #1a1d1c;
        text-decoration: none !important ;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }

      span {
        display: block;
        margin-top: 4px;
        font-size: 15px;
        color: #5e5e5e;
      }
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    width: 100%;

    .about {
      .text {
        border-radius: 10px;
        background: #f8f9fa;
        margin-left: 24px;
        padding: 10px 30px;
        max-height: 100%;

        h1 {
          color: #4f4f4f;
          font-size: 22px;
        }

        p {
          text-align: justify;
          color: #333;
        }
      }

      .rules {
        display: flex;
        margin-top: 20px;

        .badge {
          margin-left: 26px;
          display: flex;
          padding: 1px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid #414a4c;

          svg {
            width: 24px;
            height: 24.001px;
          }

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .submitter {
        text-align: end;

        button {
          margin-top: 8px;
          width: 648px;
          height: 50px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #484848;
          color: white;
          font-size: 17px;
          cursor: pointer;
        }
      }
    }
  }
`;

export default Modals;
