import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, LoadingOverlay, Modal } from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import { Trash } from "tabler-icons-react";
import { useMutation } from "@tanstack/react-query";

export const DeleteProgramModal = ({ props }) => {

  const { type, entryData } = props;
  const [ showModal, setShowModal ] = useState(false)
  const [ loaderVisible, setLoaderVisible ] = useState(false);

  const navigate = useNavigate()

  const { mutate:deleteProgram } = useMutation(["deleteProgram"],
    async (e) => {
      setLoaderVisible(true);
      await axios.delete(type === "course" ? `/api/course/${entryData.id}` : `/internship/${entryData.id}`)
    },
    {
      onSuccess: () => {
        navigate(type === "course" ? "/course/created" : "/internship/created")
        setLoaderVisible(false)
      }
    }
  )

  return(
    <DeleteProgramStyle>
      <Button m={0} color="red" variant="outline" leftIcon={<Trash/>} onClick={() => setShowModal(true)}>Delete Program</Button>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Delete program"
        >
        <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>
        <div className="deleteCourseModal">
          <p style={{color:"red"}}>Are you sure you want to delete this program?<br />This action cannot be undone.</p>
          <div style={{display:"flex",justifyContent:"center"}}>
            <button onClick={() => deleteProgram()} style={{border:"none", background:"#fee", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>Yes</button>
            <button onClick={() => setShowModal(false)} style={{border:"none", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>No</button>
          </div>
        </div>
      </Modal>
    </DeleteProgramStyle>
  )
}

const DeleteProgramStyle = styled.div`
  margin: 1rem 0;
  background-color: #fff;
  border-radius: 0.5rem;
`