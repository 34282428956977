import axios from "axios";
import { useState, useEffect } from "react";
import { DatePicker, DateRangePicker } from "@mantine/dates";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SkeletonLoader } from "../../Components/Loaders/SkeletonLoader";
import { Search } from "tabler-icons-react";
import { Button, Modal, NumberInput, Select, TextInput } from "@mantine/core";

export function ReportFilter({ props }) {
  const {
    program,
    filterOpen,
    setFilterOpen,
    reFetch,
    setReportData,
    setReportLoading,
  } = props;

  const [resetForm, setResetForm] = useState(false);
  const [programTypeChosen, setProgramTypeChosen] = useState(null);

  let domain = "";

  if (program === "taskool") {
    domain = "/reports/users_order_tip_for_admin/";
  }

  if (program === "taskilled" && programTypeChosen === "course") {
    domain = "/reports/users_order_course_for_admin/";
  }

  if (program === "taskilled" && programTypeChosen === "intershipinfo") {
    domain = "/reports/users_order_internship_for_admin/";
  }

  const { data: programChosen } = useQuery(
    [`${programTypeChosen}`],
    async () =>
      await axios
        .get(
          programTypeChosen === "course"
            ? `/api/all-courses/`
            : `/internship/activeinternshiplist/`
        )
        .then((res) => res.data)
  );

  const { data: specialities } = useQuery(
    [`specialities`],
    async () => await axios.get(`/api/v1/speciality/`).then((res) => res.data)
  );

  const { data: skillsList } = useQuery(["skillsList"], async () => {
    return await axios.get(`/api/skill_list/`).then((res) => res.data);
  });

  const { mutate: searchData, isLoading } = useMutation(
    [`searchData`],
    async (e) => {
      e.preventDefault();

      let {
        skill,
        programType,
        program,
        firstName,
        lastName,
        grade,
        dateRange,
        specificDate,
        payment,
        speciality,
        promocode,
        ulduzum,
        taskCompleted,
      } = e.target;

      let linksObj = {
        programTypeChosen:
          programType?.value && `${programType?.value}=${program?.value}&`,
        firstNameLink: firstName?.value && `first_name=${firstName?.value}&`,
        gradeLink: grade?.value && `grade=${grade?.value}&`,
        lastNameLink: lastName?.value && `last_name=${lastName?.value}&`,
        paymentLink:
          payment?.value === "1"
            ? `successfuly_paid=True&`
            : payment?.value === "2"
            ? `is_free=True&`
            : "",
        specialityLink: speciality?.value && `speciality=${speciality?.value}&`,
        startDateLink:
          dateRange?.value &&
          `begin_date=${dateRange?.value.substring(0, 10)}&`,
        endDateLink:
          dateRange?.value && `end_date=${dateRange?.value.substring(13, 23)}&`,
        specificDateLink: specificDate?.value && `date=${specificDate?.value}`,
        promocodeLink: promocode?.value && `promocode=${promocode?.value}`,
        ulduzumLink:
          ulduzum?.value === "1"
            ? `is_campaign=True`
            : ulduzum?.value === "2"
            ? `is_campaign=False`
            : "",
        taskCompletedLink:
          taskCompleted?.value === "1"
            ? `task_completed=True`
            : taskCompleted?.value === "2"
            ? `task_completed=False`
            : "",
        skillLink: skill?.value && `skill=${skill?.value}`,
      };

      let {
        skillLink,
        programTypeChosen,
        firstNameLink,
        gradeLink,
        lastNameLink,
        paymentLink,
        specialityLink,
        startDateLink,
        endDateLink,
        specificDateLink,
        promocodeLink,
        ulduzumLink,
        taskCompletedLink,
      } = linksObj;

      let response = await axios
        .get(
          `${domain}?${skillLink}${paymentLink}${programTypeChosen}${firstNameLink}${lastNameLink}${specialityLink}${gradeLink}${startDateLink}${endDateLink}${specificDateLink}${promocodeLink}${ulduzumLink}${taskCompletedLink}`
        )
        .then((res) => res.data);

      setReportData(response);
    },
    {
      onSuccess: () => {
        setFilterOpen(false);
        setReportLoading(false);
      },
    }
  );

  useEffect(() => {
    setReportLoading(true);
  }, [isLoading]);

  function resetSearch() {
    setResetForm(true);
    setFilterOpen(false);
    setTimeout(() => {
      setResetForm(false);
      reFetch();
    }, 250);
  }

  if (resetForm) {
    return <SkeletonLoader height={267} my={15} visible={true} />;
  }

  return (
    <Modal
      opened={filterOpen}
      onClose={() => setFilterOpen(false)}
      withCloseButton={false}
      size="1000px"
      overlayColor="#aaaaaa99"
    >
      <div className="filterStyle">
        <form onSubmit={(e) => searchData(e)} id="searchForm">
          <div className="group">
            <Select
              clearable
              name="programType"
              label="Program type"
              placeholder="Pick a program type"
              searchable
              data={["Course", "Internship"]?.map((program) => {
                if (program === "Course") {
                  return { value: "course", label: "Course" };
                }
                if (program === "Internship") {
                  return { value: "intershipinfo", label: "Internship" };
                }
              })}
              onChange={(e) => setProgramTypeChosen(e)}
            />
          </div>

          <div className="group">
            <Select
              disabled={programTypeChosen === null}
              clearable
              name="program"
              label="Program"
              placeholder="Pick a program"
              searchable
              data={
                programChosen
                  ? programChosen?.map((program) => {
                      return { value: program.id, label: program.title };
                    })
                  : []
              }
            />

            <Select
              disabled={programTypeChosen === null}
              clearable
              name="payment"
              label="Payment"
              placeholder="Pick payment method"
              searchable
              data={[
                { id: "0", title: "All" },
                { id: "1", title: "Paid" },
                { id: "2", title: "Free" },
              ].map((e) => ({ value: e.id, label: e.title }))}
            />

            <Select
              disabled={programTypeChosen === null}
              clearable
              name="speciality"
              label="Speciality"
              placeholder="Pick a speciality"
              searchable
              data={
                specialities
                  ? specialities?.map((speciality) => {
                      return {
                        value: speciality?.id,
                        label: speciality?.content,
                      };
                    })
                  : []
              }
            />
          </div>

          <div className="group">
            <TextInput
              disabled={programTypeChosen === null}
              placeholder="First name"
              label="First name"
              name="firstName"
            />

            <TextInput
              disabled={programTypeChosen === null}
              placeholder="Last name"
              label="Last name"
              name="lastName"
            />

            <NumberInput
              disabled={programTypeChosen === null}
              name="grade"
              placeholder="Grade"
              label="Pick a minimum grade"
              min={0}
              max={10}
            />
          </div>

          <div className="group">
            <TextInput
              disabled={programTypeChosen === null}
              name="promocode"
              label="Promo Code"
              placeholder="Used a promocode"
            />

            <Select
              disabled={programTypeChosen === null}
              clearable
              name="taskCompleted"
              label="Completed tasks"
              placeholder="User completed a task"
              searchable
              data={[
                { id: "0", title: "All" },
                { id: "1", title: "Yes" },
                { id: "2", title: "No" },
              ].map((e) => ({ value: e.id, label: e.title }))}
            />

            <Select
              disabled={programTypeChosen === null}
              clearable
              name="ulduzum"
              label="Ulduzum"
              placeholder="Ulduzum user"
              searchable
              data={[
                { id: "0", title: "All" },
                { id: "1", title: "Yes" },
                { id: "2", title: "No" },
              ].map((e) => ({ value: e.id, label: e.title }))}
            />
          </div>

          <div className="group">
            <DateRangePicker
              disabled={programTypeChosen === null}
              name="dateRange"
              label="Date Range"
              placeholder="Pick dates range"
              inputFormat="YYYY-MM-DD"
              labelFormat="YYYY-MM"
            />

            <DatePicker
              disabled={programTypeChosen === null}
              name="specificDate"
              label="Specific Date"
              placeholder="Pick a date"
              inputFormat="YYYY-MM-DD"
              labelFormat="YYYY-MM"
            />

            <Select
              disabled={programTypeChosen === null}
              name="skill"
              searchable
              clearable
              label="Skill"
              placeholder="Search for a skill"
              data={
                skillsList
                  ? skillsList?.map((skill) => {
                      return {
                        value: skill?.id,
                        label: skill?.skill,
                        group: skill?.header?.header,
                      };
                    })
                  : []
              }
            />
          </div>

          <div className="buttons">
            <Button
              disabled={isLoading}
              type="button"
              variant="outline"
              color="dark"
              onClick={(e) => resetSearch(e)}
            >
              Reset
            </Button>
            <Button
              leftIcon={<Search size={18} />}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              variant="filled"
              color="dark"
            >
              Search
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
