import { Input, Modal, Textarea } from "@mantine/core";
import axios from "axios";
import { useState, useEffect } from "react";

export function EditSectionModal({ props }) {
  const {
    entryType,
    links,
    setLoaderVisible,
    sectionContent,
    fetchCourseContent,
    entryId,
    setEditSectionModalOpen,
    editSectionModalOpen,
  } = props;

  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");

  useEffect(() => {
    if (sectionContent.length > 0) {
      setSectionTitle(sectionContent[0]?.section?.title);
      setSectionDescription(sectionContent[0]?.section?.description);
    } else {
      setSectionTitle(sectionContent?.title);
      setSectionDescription(sectionContent?.description);
    }
    return () => {
      setSectionTitle("");
      setSectionDescription("");
    };
  }, [sectionContent]);

  async function updateSection() {
    setLoaderVisible(true);
    try {
      if (entryType === "course") {
        await axios.patch(
          `/api/edit-section/${
            !sectionContent[0]?.section?.id
              ? sectionContent?.id
              : sectionContent[0]?.section.id
          }/`,
          {
            title: sectionTitle,
            description: sectionDescription,
          }
        );
      } else {
        await axios.patch(
          `/internshipsection/${
            !sectionContent[0]?.section?.id
              ? sectionContent?.id
              : sectionContent[0]?.section.id
          }/`,
          {
            title: sectionTitle,
            description: sectionDescription,
          }
        );
      }
      window.location.reload(false);
      setLoaderVisible(false);
      setEditSectionModalOpen(false);
    } catch (error) {
      setLoaderVisible(false);
    }
  }

  return (
    <Modal
      opened={editSectionModalOpen}
      onClose={() => setEditSectionModalOpen(false)}
      title="Change section title"
    >
      <div>
        <p>Section title:</p>
        <Input
          value={sectionTitle}
          onChange={(e) => setSectionTitle(e.target.value)}
        />
        <br />
        <Textarea
          label="Section Description"
          minRows={5}
          value={sectionDescription}
          onChange={(e) => setSectionDescription(e.target.value)}
        />
        <div
          className="buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem 0 0 0",
          }}
        >
          <button
            style={{
              backgroundColor: "#eee",
              width: "100%",
              border: "none",
              cursor: "pointer",
              padding: "0.5rem 1rem",
              margin: "0 .5rem 0 0",
              borderRadius: "0.2rem",
            }}
            className="cancel"
            onClick={() => setEditSectionModalOpen(false)}
          >
            <span>Cancel</span>
          </button>
          <button
            style={{
              backgroundColor: "var(--yellow)",
              width: "100%",
              border: "none",
              cursor: "pointer",
              padding: "0.5rem 1rem",
              margin: "0 0 0 .5rem",
              borderRadius: "0.2rem",
            }}
            className="submit"
            onClick={() => updateSection()}
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}
