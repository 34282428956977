import { Modal } from "@mantine/core";
import { useState } from "react";

export function FinishBeforeChaningSectionModal({props}){

  const { changeSectionModal, setSectionContentFiltered, setPendingContent, setChangeSectionModal, fetchSection } = props;

  const [ moveAnyway, setMoveAnyway ] = useState(false);

  if(moveAnyway){
    setPendingContent([])
    setSectionContentFiltered([])
    setChangeSectionModal(false)
    fetchSection(changeSectionModal)
  }

  return(
    <Modal opened={changeSectionModal} onClose={() => setChangeSectionModal(false)} title="Finish before chaning sections">
      <div>
        <p style={{color:"#dd4a4a", margin:"1.5rem 0"}}>You have not finished this section yet. Are you sure you want to change section?</p>
        <div className="buttons" style={{display:"flex", justifyContent:"center"}}>
          <button style={{backgroundColor:"#eee", border:"none", cursor:"pointer", padding:"0.6rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="cancel" onClick={() => setChangeSectionModal(false)}>
            <span>Cancel</span>
          </button>
          <button style={{backgroundColor:"#dd4a4a", color:"white", border:"none", cursor:"pointer", padding:"0.6rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="submit" onClick={() => setMoveAnyway(true)}>
            <span>Change Section</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}