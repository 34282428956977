import axios from "axios";
import { useContext, useState } from "react";
import styled from "styled-components";
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png";
import {
  Button,
  Divider,
  Image,
  Navbar,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { getCookie, setCookie } from "../../../Helpers/useCookie";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../../Helpers/Context";
import { Login } from "tabler-icons-react";

import { Alert } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

export const LoginInputs = () => {
  const { setUser } = useContext(AppContext);
  const [loginError, setLoginError] = useState(false);
  const {
    mutate: postCredentials,
    error,
    isError,
    isLoading,
  } = useMutation(
    [""],
    async (credentials) => {
      credentials.preventDefault();
      return axios.post("api/v1/login/", {
        username: credentials.target.email.value,
        password: credentials.target.password.value,
      });
    },
    {
      onSuccess: (data) => {
        setCookie("access", data.data.access, 30);
        setCookie("refresh", data.data.refresh, 30);
        getUserData();
      },
    }
  );

  const { mutate: getUserData } = useMutation(
    ["user-data"],
    async () => {
      const httpClient = axios.create({
        headers: { Authorization: `Bearer ${getCookie("access")}` },
      });
      return await httpClient.post("api/v1/user-data/").then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (data.user_type.id !== 1) {
          localStorage.setItem("taskooluser", JSON.stringify(data));
          setUser(true);
          window.location.href = "/";
        } else {
          setLoginError(true);
          setTimeout(() => {
            setLoginError(false);
          }, 1000);
        }
      },
    }
  );

  const icon = <IconInfoCircle />;

  return (
    <Xstyled>
      <Navbar
        width={{ base: 500 }}
        height={"100%"}
        p="lg"
        px={0}
        styles={{ root: { left: "initial", right: "0" } }}
      >
        <Navbar.Section>
          <div className="image">
            <Image src={taskilledShadowLogo} width={"200px"} />
          </div>
        </Navbar.Section>
        <Divider mt={"xl"} mx={"0"} color="#00000011" />

        <Navbar.Section className="welcome">
          <h1>Welcome!</h1>
          <h3>We are happy to see you again</h3>
        </Navbar.Section>
        <Navbar.Section grow className="inputs">
          <form
            onSubmit={(credentials) => postCredentials(credentials)}
            className="wrapper"
          >
            <TextInput
              name="email"
              label="E-mail"
              required
              size="md"
              placeholder="E-mail address"
              id="email"
            />
            <PasswordInput
              className="passwordInput"
              toggleTabIndex={0}
              name="password"
              label="Password"
              required
              size="md"
              placeholder="Password"
              id="password"
            />
            <Button
              loaderPosition="right"
              rightIcon={<Login />}
              disabled={isLoading}
              loading={isLoading}
              type="submit"
              mt={5}
              size="md"
              color={"dark"}
              variant="filled"
            >
              Log in
            </Button>
            {/* <Divider my="0" label="OR" labelPosition="center" /> */}
            {/* <Button mt={5} size='md' color={"dark"} variant="filled">Register</Button> */}
          </form>
          {isError && (
            <Text align="center" color={"red"}>
              {error?.response?.data?.detail}
            </Text>
          )}

          {loginError && (
            <Alert
              variant="filled"
              color="red"
              radius="md"
              title="Xəta"
              icon={icon}
            >
              Giriş icazəniz yoxdur
            </Alert>
          )}
        </Navbar.Section>
        <Navbar.Section className="inputs">
          <Button fullWidth mt={5} size="sm" color={"blue"} variant="subtle">
            Forgot password?
          </Button>
        </Navbar.Section>
      </Navbar>
    </Xstyled>
  );
};

const Xstyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .welcome {
    text-align: center;
    margin-top: 5.5rem;
    h1,
    h3 {
      font-weight: 500;
    }
    h1 {
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0.5rem;
      color: #555;
    }
  }
  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .inputs {
    width: 80%;
    margin: 0 auto;
    border-radius: 0.25rem;
    .passwordInput {
      button {
        margin-right: 1rem;
      }
    }
    .wrapper {
      padding: 1rem 1rem 1.5rem 1rem;
      display: grid;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      grid-gap: 1rem;
      input {
        min-width: 100%;
        width: 100%;
      }
    }
  }
`;
