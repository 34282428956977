import styled from "styled-components"
import { useState } from "react"
import { Avatar, Badge, Button, Checkbox, Collapse, Skeleton, Text, ThemeIcon, UnstyledButton } from "@mantine/core"
import { Book, BrandFacebook, BrandGithub, BrandLinkedin, Briefcase, ChevronDown, ChevronUp, Mail, MapPin, Phone, School, UserPlus } from "tabler-icons-react"

export function UserFlyer({ props }){

  const { data, index, selectedAccounts, setSelectedAccounts } = props

  const [ openDetailIds, setOpenDetailIds ] = useState([0])

  function showDetails(id){
    if(openDetailIds.includes(id)){
      setOpenDetailIds(prev => prev.filter(num => num !== id))
    }
    if(!openDetailIds.includes(id)){
      setOpenDetailIds(prev => [...prev, id])
    }
  }

  function selectItem(person){
    if(selectedAccounts.includes(person)){
      setSelectedAccounts(prev => prev.filter(num => num.id !== person.id) )
    }
    if(!selectedAccounts.includes(person)){
      setSelectedAccounts(prev => [ ...prev, person ])
    }
  }

  return(
    <FlyerStyle key={data?.id}>
      <div className="user">

        <div className="top">
          <div className="identification">
            <Checkbox checked={selectedAccounts?.includes(data)} size="md" color={"teal"} onClick={() => selectItem(data)}/>
            <div className="avatar">
              <Avatar variant="filled" color={"gray"} radius={"100%"} size={70} src={data?.user?.profile_img} alt="user"/>
              {/* {
                index === 2 &&
                <div className="badge">
                  <Badge fullWidth radius={"xs"} variant="gradient" gradient={{ from: 'gray.6', to: 'teal.5' }}>wishlisted</Badge>
                  <Button>
                    <Badge color="gray" variant="outline" radius={"xs"}>remove</Badge>
                  </Button>
                </div>
              } */}
            </div>
            <div className="infos">
              <div className="name">
                <Text size={16}>{data?.user?.first_name}</Text>
                <Skeleton radius={"xs"} animate={false} width={data?.user?.last_name?.length * 10} >
                  <Text>{data?.user?.last_name}</Text>
                </Skeleton>
              </div>
              <Text color={"dimmed"} size={14}>{data?.user?.speciality?.content}</Text>
              <div className="socials">
                <UnstyledButton>
                  <ThemeIcon radius={"xs"} size="" color={"gray.3"}>
                    <BrandFacebook size={20} color="#000"/>
                  </ThemeIcon>
                </UnstyledButton>
                <UnstyledButton>
                  <ThemeIcon radius={"xs"} size="" color={"gray.3"}>
                    <BrandGithub size={20} color="#000"/>
                  </ThemeIcon>
                </UnstyledButton>
                <UnstyledButton>
                  <ThemeIcon radius={"xs"} size="" color={"gray.3"}>
                    <BrandLinkedin size={20} color="#000"/>
                  </ThemeIcon>
                </UnstyledButton>
              </div>
            </div>
          </div>
          <div>
            <p>{"University of Baku"}</p>
          </div>
          <div>
            <p>{"Azerbaijan"}</p>
          </div>
          <div className="contact">
            <div className="contactInside">
              <Mail/>
              <p>{`${data?.user?.email.substr(0,1)}******@${data?.user?.email?.split('@')[1]}`}</p>
            </div>
            <div className="contactInside">
              <Phone/>
              <p>{`${data?.user?.number.replace("+994", "").substr(0,4)}*****`}</p>
            </div>
          </div>
          <Button leftIcon={<UserPlus/>} style={{ width:"150px", margin:"0 auto" }} color={"teal"}>Access user</Button>
        </div>

        <Button className="showDetails" size="xs" fullWidth onClick={() => showDetails(data.id)}>
          {
            openDetailIds.includes(data?.id) ? <ChevronUp color="gray"/> : <ChevronDown color="gray"/>
          }
        </Button>
      </div>

      <Collapse in={openDetailIds.includes(data?.id)} transitionDuration={400}>
        <div className="collapse">
          <div className="parts">
            <div className="part">
              <div className="title">
                <MapPin size={22}/>
                <h4>Location</h4>
              </div>
              <p>Baku, Azerbaijan</p>
            </div>
            <div className="part">
              <div className="title">
                <Briefcase size={22}/>
                <h4>Work</h4>
              </div>
              <p>{data?.user?.speciality?.content}</p>
            </div>
            <div className="part">
              <div className="title">
                <School size={22}/>
                <h4>Education</h4>
              </div>
              <p>University of Baku</p>
            </div>
            <div className="part">
              <div className="title">
                <Book size={22}/>
                <h4>Skills</h4>
              </div>
              <p>
                {
                  data?.skill && data.skill.map(
                    (skill, index) =>
                      `${index !== 0 ? ", " : " "} ${skill?.skill?.skill}`
                  )
                }
              </p>
            </div>
          </div>
        </div>
      </Collapse>
    </FlyerStyle>
  )
}

const FlyerStyle = styled.div`
  background-color: #E9ECEF;
  border: 1px solid #CED4DA;
  border-bottom: 4px solid #CED4DA;
  border-radius: 0.25rem 0.25rem .5rem .5rem;
  .user{
    background-color: #F8F9FA;
    border-radius: 0.25rem 0.25rem .5rem .5rem;
    box-shadow: 0px 2px 7px 0px #00000022;
    padding-bottom: 0.25rem;
    .showDetails{
      max-width: 100px;
      margin: 0 auto;
      background-color: #F8F9FA;
      :hover{
        background-color: #E9ECEF;
      }
    }
    .top{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      grid-gap: 2rem;
      justify-content: space-between;
      padding: 1.5rem 1rem .5rem 1rem;
      .contact{
        display: flex;
        flex-direction: column;
        p{
          margin: 0.5rem 0;
        }
        .contactInside{
          display: flex;
          align-items: center;
          grid-gap: .5rem;
        }
      }
      .identification{
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        width: 300px;
        .avatar{
          display: flex;
          flex-direction: column;
          grid-gap: 0.5rem;
          align-items: center;
          .badge{
            display: flex;
            flex-direction: column;
            grid-gap: 0.25rem;
            width: 100px;
            button{
              background: none;
              border: none;
              margin: 0;
              padding: 0;
              height: initial;
              width: 100%;
              div{
                width: 100%;
                cursor: pointer;
                span{
                  text-align: center;
                  width: 100%;
                }
              }
            }
          }
        }
        .infos{
          display: flex;
          flex-direction: column;
          grid-gap: .05rem;
          .socials{
            display: flex;
            grid-gap: 0.5rem;
            margin-top: .25rem;
            button{
              display: flex;
              align-items: center;
              background-color: none;
              border: 1px solid transparent;
              border-radius: 0.15rem;
              transition: all 0.15s;
              &:hover{
                border: 1px solid #222;
              }
            }
          }
          .name{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            div{
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    position: relative;
    z-index: 100;
  }
  .collapse{
    margin-top: -0.25rem;
    border-radius: 0rem 0rem .5rem .5rem;
    position: relative;
    z-index: 0;
    background-color: #F8F9FA;
    padding: 1.5rem;
    h4{
      font-weight: 500;
      margin: 0.6rem 0;
    }
    .parts{
      .part{
        display: flex;
        align-items: center;
        grid-gap: 2rem;
        .title{
          display: flex;
          align-items: center;
          grid-gap: .75rem;
          min-width: 120px;
        }
        p{
          color: #888;
          margin: 0;
        }
      }
    }
  }
`