// import axios from "axios";
// import styled from "styled-components";
// import { useState, useContext } from "react";
// import { AppContext } from "../../Helpers/Context";
// import { Button, FileInput, TextInput } from "@mantine/core";
// import { ProgramCard } from "../ProgramCard";

// export const CreateProgramForm = ({ props }) => {
//   const { user } = useContext(AppContext);
//   const { type, nextStep, setNewCreatedInternshipId } = props;

//   const [values, setValues] = useState({ title: "", image: "" });
//   const [isLoading, setIsLoading] = useState(false);
//   const { title, image } = values;

//   const submitAssignment = async (e) => {
//     setIsLoading(true);
//     e.preventDefault();

//     const formX = new FormData();
//     formX.append("title", title);
//     formX.append("image", image, image.name);
//     formX.append("is_active", true);
//     formX.append("is_shared", false);
//     type === "course"
//       ? formX.append("teacher", user?.id)
//       : formX.append("user", user?.id);

//     const response = await axios.post(
//       type === "course" ? "api/course/" : "internship/create/",
//       formX
//     );
//     if (response.status === 201) {
//       setIsLoading(false);
//       setNewCreatedInternshipId(response.data.id);
//       nextStep();
//     }
//   };

//   return (
//     <ProgramDetailFormStyle>
//       <form onSubmit={(e) => submitAssignment(e)}>
//         <ProgramCard props={{ image, title, link: "", type: "" }} />
//         <div className="inputs">
//           <FileInput
//             required
//             label="Image"
//             placeholder="Upload an image"
//             accept="image/*"
//             onChange={(e) => {
//               setValues({ ...values, image: e });
//             }}
//           />
//           <TextInput
//             error={title?.length > 150}
//             required
//             placeholder="Program Title"
//             label={`Title (${title?.length}/150)`}
//             value={title}
//             onChange={(e) => setValues({ ...values, title: e.target.value })}
//           />
//           <Button
//             disabled={title?.length < 1 || !image?.name}
//             mt={10}
//             color={"dark"}
//             type="submit"
//             loading={isLoading}
//             size="sm"
//           >
//             Create
//           </Button>
//         </div>
//       </form>
//     </ProgramDetailFormStyle>
//   );
// };

// const ProgramDetailFormStyle = styled.div`
//   width: 80%;
//   margin: 1rem auto;
//   form {
//     padding: 1rem 0;
//     display: flex;
//     grid-gap: 2rem;
//     .inputs {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       margin: 2rem 0;
//       grid-gap: 0.75rem;
//     }
//   }
//   @media screen and (max-width: 1024px) {
//     form {
//       padding: 1rem 0;
//       margin: 0rem;
//       grid-template-columns: 1fr;
//       grid-gap: 0rem;
//     }
//   }
// `;

import axios from "axios";
import styled from "styled-components";
import { useState, useContext } from "react";
import { AppContext } from "../../Helpers/Context";
import { Button, FileInput, TextInput } from "@mantine/core";
import { ProgramCard } from "../ProgramCard";
import {useMutation} from '@tanstack/react-query'

export const CreateProgramForm = ({ props }) => {
  const { user } = useContext(AppContext);
  const { type, nextStep, setNewCreatedInternshipId } = props;

  const [values, setValues] = useState({ title: "", image: "",theacher: user.id });
  const [isLoading, setIsLoading] = useState(false);
  const { title, image } = values;

  
  let link = 
    type === "course" ? "api/course/" :
    type === "internship" ? "internship/create/" :
    type === "hackathon" && "/hackathon/create/"

    const {mutate :createProgram} = useMutation({
      mutationFn : async (formX) => await axios.post(link, formX),
      onSuccess : (data) => {
        setIsLoading(false);
        setNewCreatedInternshipId(data.data.id);
        nextStep();
      },
      onError : (error) => {
       console.log(error);
     }
    })

  const submitAssignment = async (e) => {
    setIsLoading(true);
    e.preventDefault();
   console.log('values',values);
    const formX = new FormData();
    formX.append("title", values.title);
    formX.append("image", values.image,);
    formX.append("teacher", values.theacher );

    formX.append("is_active", true);
    formX.append("is_shared", false);
    type === "course"
      ? formX.append("teacher", user?.id)
      : formX.append("user", user?.id);
    createProgram(formX);
  };

  return (
    <ProgramDetailFormStyle>
      <form onSubmit={(e) => submitAssignment(e)}>
        <ProgramCard props={{ image, title, link: "", type: "" }} />
        <div className="inputs">
          <FileInput
            required
            label="Image"
            placeholder="Upload an image"
            accept="image/*"
            onChange={(e) => {
              setValues({ ...values, image: e });
            }}
          />
          <TextInput
            error={title?.length > 150}
            required
            placeholder="Program Title"
            label={`Title (${title?.length}/150)`}
            value={title}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
          <Button
            disabled={title?.length < 1 || !image?.name}
            mt={10}
            color={"dark"}
            type="submit"
            loading={isLoading}
            size="sm"
          >
            Create
          </Button>
        </div>
      </form>
    </ProgramDetailFormStyle>
  );
};

const ProgramDetailFormStyle = styled.div`
  width: 80%;
  margin: 1rem auto;
  form {
    padding: 1rem 0;
    display: flex;
    grid-gap: 2rem;
    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      grid-gap: 0.75rem;
    }
  }
  @media screen and (max-width: 1024px) {
    form {
      padding: 1rem 0;
      margin: 0rem;
      grid-template-columns: 1fr;
      grid-gap: 0rem;
    }
  }
`;
