import styled from 'styled-components';

export const CourseContentStyle = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 0.25rem;
  .quizModal{
    background: #000000aa;
    position: absolute;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
    top: 3.5rem;
    bottom: 0;
    .quizContainer{
      /* position: absolute; */
      /* z-index: 100; */
      background: #eaecf0;
      border-radius: 0.5rem;
      border: 1px solid #aaa;
      width: 80%;
      margin: 2rem auto 0 auto;
    }
  }
  a{
    text-decoration: none;
    color: #000;
  }
  h2,h3,h4{
    font-weight: 500;
    margin: 0;
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cfd3db;
    padding: 1rem 7rem;
    ins{
      color: #333;
      font-weight: lighter;
      text-decoration: none;
      margin-right: 0.5rem;
    }
    a{
      display: flex;
      align-items: center;
      color: #000;
      svg{
        margin-right: .5rem;
      }
    }
  }
  .sectionsList{
    width: 85%;
    margin: 2rem auto 3rem auto;
    h3{
      margin-bottom: .5rem;
      font-weight: lighter;
    }
    .wrapper{
      display: flex;
      align-items: center;
      .inside{
        overflow-x: scroll;
        overflow-y: hidden;
        ::-webkit-scrollbar {
            height: .5rem;
        }
        display: flex;
        align-items: center;
        border: 1px solid #dcdfe6;
        border-radius: 0.25rem;
        padding: .5rem;
        width: 100%;
        button{
          border: 1px solid transparent;
          background-color: #e5e7ec;
          padding: .5rem 1rem;
          margin: 0 .5rem;
          border-radius: 0.25rem;
          min-width: max-content;
          cursor: pointer;
          transition: all 0.2s;
          :hover{
            background-color: #b8bdc7;
          }
        }
        ins{
          padding: .4rem 0;
          border-right: 3px solid #f1f3f6;
        }
        .chosenSection{
          background-color: #b8bdc7;
          border: 1px solid #555;
        }
      }
      .addSection{
        display: flex;
        /* width: 100%; */
        justify-content: center;
        align-items: center;
        background: none;
        padding: 0.5rem .8rem;
        border: 2px dashed #b8bdc7;
        border-radius: 0.25rem;
        margin-left: 1rem;
        cursor: pointer;
        transition: 0.1s;
        min-width: max-content;
        :hover{
          background-color: #eaecf0;
        }
        svg{
          margin-right: 0.5rem;
        }
      }
    }
  }
  .container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 5rem;
    width: 85%;
    margin: 1rem auto;
    .leftSide{
      h3{
        font-weight: lighter;
        margin-bottom: .75rem;
      }
      .section{
        display: flex;
        flex-direction: column;
        border-radius: 0.25rem;
        /* border: 1px solid #dcdfe6; */
        padding: .5rem 0;
        .noContent{
          margin: 2rem auto;
          h3{
            display: flex;
            svg{
              margin-right: 0.5rem;
            }
          }
        }
        .entry{
          /* background-color: #eaecf0; */
          margin: 0 0 2rem 0;
          border-radius: 0.25rem;
          .inputs{
            overflow: hidden;
            border-radius: 0 0 0.25rem 0.25rem;
            border: 1px solid #cfd3db;
            border-top: none;
            padding: 1rem;
            .replaceWith{
              display: flex;
              align-items: center;
              background-color: #f8f8f8;
              padding: 0.25rem 0.25rem;
              border-radius: 0.25rem;
              p{
                margin: 0;
                margin-right: 0.5rem;
              }
            }
          }
          .entryContent{
            overflow: hidden;
            border-radius: 0 0 0.25rem 0.25rem;
            border: 1px solid #cfd3db;
            border-top: none;
            padding: 1.5rem 2rem;
            hr{
              border: none;
              border-bottom: 1px solid #eee;
              margin: 1rem 0;
            }
            p{
              margin-bottom: 0;
            }
            label{
              font-weight: 500;
              ::after{
                content: ":";
              }
            }
          }
          .entryHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #eaecf0;
            border: 1px solid #eaecf0;
            border-radius: 0.25rem 0.25rem 0 0;
            padding: 0 1rem;
            .removePendingItem{
              display: flex;
              margin: 0.1rem 0;
              margin-left: auto;
            }
            .editButtons{
              display: flex;
              .finishSection{
                display: flex;
                align-items: center;
                background-color: #4aa368;
                border-radius: 0.25rem;
                color: #fff;
                cursor: pointer;
                margin-right: 0.5rem;
                padding-right: 1rem;
                svg{
                  margin-right: .5rem;
                  :hover{
                    background: initial;
                  }
                }
              }
              .cancelEditing{
                display: flex;
                align-items: center;
                background-color: #a34a5c;
                border-radius: 0.25rem;
                padding-right: 1rem;
                color: #fff;
                cursor: pointer;
                svg{
                  margin-right: .5rem;
                  :hover{
                    background: initial;
                  }
                }
              }
            }
            .editSection{
              display: flex;
              align-items: center;
              /* background-color: #D4D7DD;
              border-radius: 0.25rem; */
              cursor: pointer;
              svg{
                margin-right: .5rem;
                :hover{
                  background: initial;
                }
              }
            }
            button{
              border: none;
              background: none;
              margin: 0;
              svg{
                cursor: pointer;
                margin-right: -0.5rem;
                border-radius: 0.25rem;
                transition: all 0.2s;
                :hover{
                  background-color: #b8bdc7;
                }
              }
            }
            position: relative;
            p{
              position: absolute;
              margin-left: -1rem;
              margin: 0.5rem auto;
              display: flex;
              align-items: center;
              left: 50%;
              transform: translateX(-50%);
              color: #000;
              svg{
                margin-right: .5rem;
              }
            }
          }
        }
      }
      .addedContent{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        .addedItem{
          margin: 1rem 2rem;
          input{
            margin-bottom: 1rem;
          }
          .inputs{
            border: 1px solid #aaa;
            padding: 1rem 1.5rem;
            border-top: none;
            border-radius: 0 0 0.3rem 0.3rem;
            overflow: hidden;
            background-color: #fcfcfc;
          }
          .itemHeader{
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #D4D7DD;
            border: 1px solid #D4D7DD;
            padding-left: 1rem;
            border-radius: 0.3rem 0.3rem 0 0;
            /* padding-top: 0.25rem; */;
            div{
              display: flex;
              align-items: center;
              margin: 0;
              padding: 0;
              p{
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                svg{
                  margin-right: .75rem;
                }
              }
            }
            button{
              background: none;
            }
          }
        }
      }
    }
    .rightSide{
      h3{
        /* color: #; */
        font-weight: lighter;
        font-size: 16px;
        margin-bottom: .5rem;
      }
      .currentlyEditingTitle{
        display: flex;
        flex-direction: column;
        ins{
          text-decoration: none;
          font-weight: 500;
        }
        h4{
          margin: 0;
          margin-top: 0rem;
          font-weight: 400;
        }
        p{
          margin: 0.5rem 0 1rem 0;
        }
        .editSection{
          background-color: #fff;
          border: none;
          padding: 0.25rem 0;
          margin-top: .5rem;
          border-radius: 0.25rem;
          cursor: pointer;
          border: 1px solid #cfd3db;
          transition: all 0.1s;
          :hover{
            background-color: #eaecf0;
            border: 1px solid transparent;
          }
        }
        .removeSection{
          background-color: #fff;
          border: none;
          padding: 0.25rem 0;
          margin-top: .5rem;
          border-radius: 0.25rem;
          cursor: pointer;
          color: red;
          border: 1px solid #cfd3db;
          transition: all 0.1s;
          :hover{
            background-color: #eaecf0;
            border: 1px solid transparent;
          }
        }
      }
      hr{
        border: none;
        border-bottom: 1px solid #eee;
        margin: 1.6rem 0;
      }
      .tools{
        .wrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0.3rem;
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.3rem;
            width: 100%;
            cursor: pointer;
            margin: .25rem 0rem;
            padding: .5rem 1rem;
            border: 1px solid #ccc;
            background-color: #fff;
            transition: 0.1s;
            svg{
              margin-right: 0.5rem;
            }
            :hover{
              background-color: #eaecf0;
              border: 1px solid transparent;
            }
          }
        }
      }
      .manageSections{
        .wrapper{
          .float{
            position: fixed;
          }
          .publish{
            width: 100%;
            background-color: var(--yellow);
            border: none;
            border-radius: 0.3rem;
            font-weight: 500;
            cursor: pointer;
            height: 2.5rem;
            color: #000;
            :hover{
              background-color: var(--yellow-shadow);
            }
          }
        }
      }
    }
  }
`