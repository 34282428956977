import { LoginInputs } from './LoginInputs'
import styled from 'styled-components'
import { AppShell, Divider } from '@mantine/core'
import { ChangingPictures } from '../../../Components/ChangingPictures'
import { useMediaQuery } from '@mantine/hooks'

export const Login = () => {

  const matches = useMediaQuery('(max-width: 900px)');

  return (
    <AppShell
      padding="0"
      navbar={<LoginInputs/>}
      styles={(theme) => ({
        root:{ "--mantine-navbar-width":"0", wdith:"0" },
        main:{ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      <LoginStyle>
        <div className="picture">
          <div className="text">
            <div>
              <h1>Taskilled</h1>
              <Divider orientation="vertical" size={2} color="#ffffffaa"/>
              <h2>Dashboard</h2>
            </div>
            <h2>Learn in practice</h2>
          </div>
          <div className="img">
            <ChangingPictures/>
          </div>
        </div>
      </LoginStyle>
    </AppShell>
  )
}

const LoginStyle = styled.div`
  position: fixed;
  overflow: hidden;
  width: 80%;
  .picture{
    .text{
      position: absolute;
      z-index: 100;
      color:#ffffffbb;
      margin: 1rem;
      div{
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
      }
      h1{
        margin: 0;
      }
      h2{
        font-weight: 500;
        margin: 0;
      }
    }
  }
  .img{
    background-color: #000;
  }
`