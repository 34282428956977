import { useContext, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  Briefcase,
  BuildingBank,
  ChevronDown,
  DoorExit,
  Id,
  Map,
  Point,
  ReportAnalytics,
  School,
  Settings,
  Shape2,
  Users,
  Backpack,
  FileSettings,
} from "tabler-icons-react";
import {
  Accordion,
  Button,
  Divider,
  Navbar,
  Popover,
  ScrollArea,
  Text,
} from "@mantine/core";
import taskilledShadowLogo from "../Assets/Images/taskilledShadowLogo.png";
import { AppContext } from "../Helpers/Context";

export function SideBar() {
  const [accordionValue, setAccordionValue] = useState(null);

  const { user, setUser } = useContext(AppContext);

  const location = useLocation();

  function logout() {
    localStorage.setItem("taskooluser", null);
    setUser(null);
    location.pathname = "/";
    // remove cookies
    document.cookie =
      "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  return (
    <SideBarStyle>
      <Navbar className="sidebarWrapper" width={{ base: 280 }} height={"100%"}>
        <Navbar.Section>
          <Button
            to="/"
            component={Link}
            variant="subtle"
            fullWidth
            color="dark"
            className="logo"
          >
            <img width={160} src={taskilledShadowLogo} alt="Logo" />
          </Button>
        </Navbar.Section>

        <Navbar.Section grow component={ScrollArea} className="growComp">
          <Divider color="#ddd" />

          <p className="label">Manage</p>

          <Accordion
            chevron={<ChevronDown size={22} />}
            value={accordionValue}
            onChange={setAccordionValue}
            variant="filled"
            styles={{
              control: { padding: ".5rem .75rem", fontSize: "16px" },
              content: { padding: "0" },
            }}
          >
            <Accordion.Item value="internships">
              <Accordion.Control icon={<Map />}>Internship</Accordion.Control>
              <Accordion.Panel>
                <Button
                  leftIcon={
                    <Point
                      color={
                        location.pathname === "/internship/create"
                          ? "#FFD43B"
                          : "#ccc"
                      }
                    />
                  }
                  component={Link}
                  to="/internship/create"
                  fullWidth
                  variant="subtle"
                  color="dark"
                >
                  Create
                </Button>
                <Button
                  leftIcon={
                    <Point
                      color={
                        location.pathname === "/internship/created"
                          ? "#FFD43B"
                          : "#ccc"
                      }
                    />
                  }
                  component={Link}
                  to="/internship/created"
                  fullWidth
                  variant="subtle"
                  color="dark"
                >
                  My internships
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Accordion
            chevron={<ChevronDown size={22} />}
            value={accordionValue}
            onChange={setAccordionValue}
            variant="filled"
            styles={{
              control: { padding: ".5rem .75rem", fontSize: "16px" },
              content: { padding: "0" },
            }}
          >
            <Accordion.Item value="courses">
              <Accordion.Control icon={<Shape2 />}>Course</Accordion.Control>
              <Accordion.Panel>
                <Button
                  leftIcon={
                    <Point
                      color={
                        location.pathname === "/course/create"
                          ? "#FFD43B"
                          : "#ccc"
                      }
                    />
                  }
                  component={Link}
                  to="/course/create"
                  fullWidth
                  variant="subtle"
                  color="dark"
                >
                  Create
                </Button>
                <Button
                  leftIcon={
                    <Point
                      color={
                        location.pathname === "/course/created"
                          ? "#FFD43B"
                          : "#ccc"
                      }
                    />
                  }
                  component={Link}
                  to="/course/created"
                  fullWidth
                  variant="subtle"
                  color="dark"
                >
                  My courses
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            fullWidth
            variant="subtle"
            to="/hackathon/created"
            disabled={user?.id !== 25}
            component={Link}
            leftIcon={<Backpack />}
            color={
              location?.pathname === "/hackathon/created" ? "yellow" : "dark"
            }
          >
            Hackathons
          </Button>

          <Button
            disabled
            fullWidth
            variant="subtle"
            color="dark"
            leftIcon={<Briefcase />}
          >
            Vacancy
          </Button>
          <Button
            fullWidth
            disabled={user?.id !== 25}
            to="/modulemanagement"
            component={Link}
            color={
              location?.pathname === "/modulemanagement" ? "yellow" : "dark"
            }
            variant="subtle"
            leftIcon={<FileSettings />}
          >
            Module Management
          </Button>

          <p className="label">Analytics</p>
          <Button
            disabled={user?.id !== 25}
            fullWidth
            component={Link}
            to="/reports"
            variant="subtle"
            color={location?.pathname === "/reports" ? "yellow" : "dark"}
            leftIcon={<ReportAnalytics />}
          >
            Reports
          </Button>
          <Button
            disabled={user?.id !== 25}
            component={Link}
            to="/talants"
            fullWidth
            variant="subtle"
            color={location?.pathname === "/talants" ? "yellow" : "dark"}
            leftIcon={<Map />}
          >
            Talant Data
          </Button>

          <p className="label">Staff</p>
          <Button
            fullWidth
            component={Link}
            to="/users"
            disabled={user?.id !== 25}
            color={location?.pathname === "/users" ? "yellow" : "dark"}
            variant="subtle"
            leftIcon={<Users />}
          >
            Users
          </Button>
          <Button
            disabled
            fullWidth
            variant="subtle"
            color="dark"
            leftIcon={<School />}
          >
            Mentors
          </Button>
          <Button
            disabled
            fullWidth
            variant="subtle"
            color="dark"
            leftIcon={<BuildingBank />}
          >
            Schools
          </Button>

          <p className="label">Application</p>
          <Button
            disabled
            fullWidth
            component={Link}
            to="/settings"
            variant="subtle"
            color="dark"
            leftIcon={<Settings />}
          >
            Settings
          </Button>
        </Navbar.Section>

        <Popover
          position="top"
          withArrow
          shadow="md"
          closeOnClickOutside
          closeOnEscape
        >
          <Popover.Target>
            <Button
              leftIcon={<Id size={24} />}
              m={15}
              color="gray.9"
              variant="outline"
            >
              <Text align="center">
                {user?.first_name} {user?.last_name !== "." && user?.last_name}
              </Text>
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Button
              onClick={() => logout()}
              color={"red"}
              variant="subtle"
              leftIcon={<DoorExit />}
              fullWidth
            >
              Log out
            </Button>
          </Popover.Dropdown>
        </Popover>
      </Navbar>
    </SideBarStyle>
  );
}

const SideBarStyle = styled.div`
  .sidebarWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    .logo {
      height: 40px;
      margin-top: 0.5rem;
      img {
        border-radius: 0.25rem;
      }
      :hover {
        background-color: initial;
      }
    }
    .growComp {
      display: flex;
      align-items: flex-start;
      grid-gap: 1rem;
      padding: 0 1.1rem;
      button,
      a {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.25rem;
        font-weight: 500;
        border-radius: 0.15rem;
      }
      .currentPage {
        border: 1px solid #aaa;
      }
      .companyDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        p {
          padding: 0;
          margin: 0;
          font-size: 15px;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 0.25rem;
        }
      }
      .label {
        color: #888;
        font-size: 14px;
        margin: 0.5rem 0;
      }
    }
    .footer {
      margin: 0.5rem 0 0.75rem 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
