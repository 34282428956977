import { useEffect, useState } from "react";
import { CreateProgramForm } from "./CreateProgramForm";
import { Stepper, Text } from "@mantine/core";
import { EditProgramInfo } from "../ProgramInfo/EditProgramInfo/EditProgramInfo";
import { useLocation } from "react-router-dom";

export const CreateProgram = ({ props }) => {
  const { type } = props;

  const [active, setActive] = useState(0);
  const [newCreatedInternshipId, setNewCreatedInternshipId] = useState(null);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));

  const location = useLocation();

  useEffect(() => {
    setActive(0);
  }, [location.pathname]);

  return (
    <div>
      <Stepper
        allowNextStepsSelect={false}
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
        color="yellow.4"
        radius="sm"
        mx={40}
        mr={80}
        my={10}
      >
        <Stepper.Step
          allowStepSelect={false}
          label="First step"
          description={`Create a ${type}`}
        >
          <Text mt={20} weight={600} align="center">
            Step 1: Fill the inputs and initialize the {type}.
          </Text>
          <CreateProgramForm
            props={{ type, nextStep, setNewCreatedInternshipId }}
          />
        </Stepper.Step>

        <Stepper.Step
          allowStepSelect={false}
          label="Second step"
          description={`Add ${type} information`}
        >
          <Text mb={20} mt={20} weight={600} align="center">
            Step 2: Please fill the required fields
          </Text>
          <EditProgramInfo props={{ type, newCreatedInternshipId }} />

        </Stepper.Step>

        <Stepper.Completed>
          You have completed the {type} creation process
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};
