import { React, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Plus } from "tabler-icons-react";
import { useNotifications } from '@mantine/notifications';
import { Button, Checkbox, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

export const Education = ({ props }) => {

  const { educationData, showPrivateFeatures, isTaskool } = props;

  let texts = isTaskool ?
  {
    header: "Təhsil",
    bSend: "Göndər",
    bEdit: "Redaktə et",
    bDelete: "Sil",
    bCancel: "Ləğv et",
    l1: "Təhsil ocağı",
    l2: "Təhsil növü",
    l3: "Başlama tarixi",
    l4: "Hazırda oxuyuram",
    l5: "Bitmə tarixi",
    p1: "10 nömrəli liset",
    p2: "Orta təhsil",
    present: "İndi",
    nt1: "Təshil əlavə edildi",
    nt2: "Təshil redaktə edildi",
    nt3: "Təshil silindi",
    ntm1: "Təshil uğurla əlavə edildi",
    ntm2: "Təshil uğurla redaktə edildi",
    ntm3: "Təshil uğurla silindi",
    e1: "Təshil əlavə edilərkən xəta baş verdi",
    e2: "Zəhmət olmasa tələb olunan xanaları doldurun",
    e3: "Hal-hazırda işə getmirsinizsə, bitmə tarixini doldurmalısınız",
  }
  :
  {
    header: "Education",
    bSend: "Submit",
    bEdit: "Edit",
    bDelete: "Remove",
    bCancel: "Cancel",
    l1: "College",
    l2: "Education",
    l3: "Start date",
    l4: "Currently studying",
    l5: "End date",
    p1: "University of Warsaw",
    p2: "Computer Science",
    present: "Present",
    nt1: "Education added",
    nt2: "Education edited",
    nt3: "Education deleted",
    ntm1: "Education successfully added",
    ntm2: "Education successfully edited",
    ntm3: "Education successfully deleted",
    e1: "An error occurred while adding education",
    e2: "Please fill in the required fields",
    e3: "If you are not currently working, please fill in the end date",
  }

  return (
    <Educationstyle>
      <div className="wrapperEducation">
        <div className="education">

          <div className="title">
            <h2>{texts?.header}</h2>
          </div>

          <form onSubmit={e => e.preventDefault()}>
            {
              educationData?.map((education, index) => {
                return (
                  <div key={index} id={education.id} className="insideWrapper">
                    <div className="box">
                      <div className="info">
                        <h3>{education.college}</h3>
                        <p>{education.education}</p>
                        <ins>{education.is_present === true ? education?.start_date + " - İndi" : education?.start_date + " - " + education?.end_date}</ins>
                      </div>
                    </div>
                    <hr />
                  </div>
                )
              })
            }
          </form>
        </div>
      </div>
    </Educationstyle>
  );
};

const Educationstyle = styled.div`
  display: flex;
  button{
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  .wrapperEducation{
    width: 92%;
    margin: -1rem auto 0 auto;
    h2,h3{
      font-weight: 500;
    }
    .education{
      margin-bottom: 2rem;
      .insideWrapper{
        margin-top: 1rem;
        hr{
          border: none;
          border-bottom: 1px solid #ddd;
          margin: 1rem;
        }
      }
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addItem{
          background: none;
          font-size: 17px;
          color: #2f80ed;
          font-weight: 400;
          border: none;
          cursor: pointer;
        }
        svg{
          color: #000;
        }
      }
      .box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;
        margin-top: 1rem;
        .manageEntry{
          display: flex;
          margin-right: 2rem;
          button{
            display: flex;
            border: none;
            font-weight: 500;
          }
        }
        .info{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          h3{
            margin: 0;
            font-size: 17px;
          }
          p{
            margin: .5rem 0;
          }
          ins{
            margin: 0;
            text-decoration: none;
            font-size: 14px;
            color: #666;
          }
        }
      }
      .editing{
        padding: 1rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        align-items: center;
        .buttons{
          .groupOne{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
          }
          button{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 1rem 0;
    .wrapperEducation{
      width: 100%;
      padding: .5rem .5rem .5rem .5rem;
      padding: .5rem 0rem .5rem 0rem;
      margin: -1rem auto 0 auto;
      .education{
        .title{
          width: 92%;
          margin: 0 auto;
        }
        .box{
          .manageEntry{
            display: flex;
            margin-right: .5rem;
          }
        }
        .editing{
          margin: 0 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 335px) {
    .wrapperEducation{
      .education{
        .box{
          display: block;
          .manageEntry{
          }
        }
      }
    }
  }
`