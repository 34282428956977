import styled from "styled-components"
import { Link } from "react-router-dom"
import { Button, Divider } from "@mantine/core"
import { Edit, Tools } from "tabler-icons-react"
import { ProgramPrivacy } from "./ProgramPrivacy"
import { ManagaMentors } from "./ManagaMentors"
import { DeleteProgramModal } from "./DeleteProgramModal"

export function ManageProgram({ props }) {

  const { user, type, entryData, setEntryData } = props

  return (
    ( user?.user_type?.type !== "Student" && (user?.id === entryData?.teacher?.id || user?.id === entryData?.user?.id) ) &&
    <ManageStyle>
      <div className="manageWrapper">
        <ProgramPrivacy props={{ user, type, entryData, setEntryData }} />
        <Divider mt={15}/>
        <div className="editProgram">
          <Button variant="default" component={Link} to={type === "course" ? `/course/edit/${entryData.id}/` : `/internship/edit/${entryData.id}/`} leftIcon={<Edit/>}>Edit program info</Button>
          <Button variant="default" component={Link} to={type === "course" ? `/course/add/${entryData.id}/` : `/internship/add/${entryData.id}/`} leftIcon={<Tools/>}>Add/Edit program content</Button>
          <DeleteProgramModal props={{ type, entryData }}/>
        </div>
        <ManagaMentors props={{ type, entryData }}/>
      </div>
    </ManageStyle>
  )
}

const ManageStyle = styled.div`
  padding: 1.5rem 1.5rem;
  button,a{
    font-weight: 500;
  }
  .manageWrapper{
    .editProgram{
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 1rem;
    }
  }
`