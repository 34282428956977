import { React } from "react";
import { AppContextProvider } from "./Helpers/Context";
import { RouteLogic } from "./Helpers/RouterLogic";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SuspenseSpinner } from "./Components/Loaders/SuspenseSpinner";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    }
  })

  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <SuspenseSpinner>
          <RouteLogic/>
        </SuspenseSpinner>
        <ReactQueryDevtools/>
      </QueryClientProvider>
    </AppContextProvider>
  );
}

export default App;