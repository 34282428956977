import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { LoadingOverlay, Pagination, TextInput } from "@mantine/core";
import { Avatar } from "@mantine/core";

const Users = () => {
  const [activePage, setPage] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const chunk = (array, size) => {
    if (!array || !array.length) {
      return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...chunk(tail, size)];
  };

  const {
    data: allUsers,
    isLoading,
    isFetching,
  } = useQuery(["all users"], async () => {
    const response = await axios.get(`user/all_users_list`);
    setUsersList(response.data);
    return response.data;
  });

  const filteredUsers = usersList.filter((user) =>
    [
      user?.first_name,
      user?.last_name,
      user?.email,
      user?.first_name + " " + user?.last_name,
    ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const usersPerPage = 25; // Number of users per page
  const paginatedUsers = chunk(filteredUsers, usersPerPage);
  const currentPageUsers = paginatedUsers[activePage - 1] || [];

  return (
    <div>
      <LoadingOverlay
        color="yellow"
        visible={isLoading || isFetching}
        loaderProps={{ size: "lg", color: "yellow.4", variant: "bars" }}
      />

      <TextInput
        mb={20}
        placeholder="Search by name, surname, or email"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      {searchQuery && filteredUsers.length === 0 ? (
        <div>No users found with that credentials.</div>
      ) : searchQuery ? (
        <div style={{ overflowY: "auto", maxHeight: "700px" }}>
          <table
            style={{
              minWidth: "800px",
              borderCollapse: "collapse",
              borderSpacing: "0",
            }}
          >
            <thead>
              <tr>
                <th style={{ borderBottom: "1px solid #ddd" }}>Avatar</th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                  Fullname
                </th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((item) => (
                <tr key={item.id}>
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      textAlign: "center",
                    }}
                  >
                    <Avatar src={item.profile_img} alt="avatar" />
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {item.first_name} {item.last_name}
                    </span>
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      textAlign: "center",
                    }}
                  >
                    {item.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div style={{ overflowY: "auto", maxHeight: "700px" }}>
            <table
              style={{
                minWidth: "800px",
                borderCollapse: "collapse",
                borderSpacing: "0",
              }}
            >
              <thead>
                <tr>
                  <th style={{ borderBottom: "1px solid #ddd" }}>Avatar</th>
                  <th
                    style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                  >
                    Fullname
                  </th>
                  <th
                    style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                  >
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPageUsers.map((item) => (
                  <tr key={item.id}>
                    <td
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <Avatar src={item.profile_img} alt="avatar" />
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {item.first_name} {item.last_name}
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {item.email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            total={paginatedUsers.length}
            value={activePage}
            onChange={setPage}
            mt="sm"
          />
        </>
      )}
    </div>
  );
};

export default Users;
