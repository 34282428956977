import { Spoiler } from "@mantine/core";
import styled from "styled-components";

export const Certifications = ({ props }) => {

  const { certificationData, isTaskool } = props;

  let texts = isTaskool ?
  {
    header: "Sertifikatlar",
  }
  :
  {
    header: "Certifications",
  }

  return (
    <CertStyle>
      <div className="wrapperCert">
        <div className="cert">

          <div className="title">
            <h2>{texts?.header}</h2>
          </div>

          <Spoiler maxHeight={400} showLabel="Show more" hideLabel="Hide">
              {
                certificationData?.map((certification, index) => {
                  return (
                    <div key={index} id={certification?.id} className="insideWrapper">
                      <div className="box">
                        <div className="info">
                          <img src={certification?.certificate} alt="" />
                          <h2>{certification?.title?.length > 80 ? certification?.title?.substring(0, 80) + ".." : certification?.title}</h2>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
          </Spoiler>

        </div>
      </div>
    </CertStyle>
  );
};

const CertStyle = styled.div`
  display: flex;
  padding-top: 2rem;
  background: #fff;
  border-radius: .75rem;
  border: 1px solid #DEE2E6;
  button{
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  img{
    object-fit: cover;
    border-radius: 0.25rem;
    width: 200px;
    height: 100px;
  }
  .wrapperCert{
    width: 92%;
    margin: 0rem auto 0 auto;
    h2,h3{
      font-weight: 500;
    }
    .cert{
      margin-bottom: 2rem;
      .insideWrapper{
        margin-top: 1rem;
        hr{
          border: none;
          border-bottom: 1px solid #ddd;
          margin: 1rem;
        }
      }
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addItem{
          background: none;
          font-size: 17px;
          color: #2f80ed;
          font-weight: 400;
          border: none;
          cursor: pointer;
        }
        svg{
          color: #000;
        }
      }
      .box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;
        margin-top: 1rem;
        .manageEntry{
          display: flex;
          margin-right: 2rem;
          button{
            display: flex;
            border: none;
            font-weight: 500;
          }
        }
        .info{
          display: flex;
          align-items: flex-start;
          width: 100%;
          align-items: center;
          h2{
            font-size: clamp(1rem, 2vw, 1.1rem);
            margin: 0 auto;
            margin-left: 1rem;
            margin-right: 1rem;
          }
          p{
            margin: .5rem 0;
          }
          ins{
            margin: 0;
            text-decoration: none;
            font-size: 14px;
            color: #666;
          }
        }
      }
      .editing{
        padding: 1rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        align-items: center;
        .buttons{
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1rem;
          button{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 0 0 0;
    margin: 0.75rem 0;
    img{
      width: max(140px, 30%);
      height: 70px;
      order: 2;
    }
    .wrapperCert{
      width: 100%;
      padding: .5rem .5rem .5rem .5rem;
      padding: .5rem 0rem .5rem 0rem;
      margin: -1rem auto 0 auto;
      .cert{
        .title{
          width: 92%;
          margin: 0 auto;
        }
        .box{
          .manageEntry{
            display: flex;
            margin-right: .5rem;
          }
          .info{
            display: flex;
            align-items: flex-start;
            width: 100%;
            align-items: flex-start;
            justify-content: left;
            flex-direction: column;
            h2{
              font-size: clamp(1.1rem, 2vw, 1.1rem);
              margin: 0;
              margin-bottom: 1rem;
              order: 1;
            }
          }
        }
        .editing{
          margin: 0 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 335px) {
    .wrapperCert{
      .cert{
        .box{
          display: block;
          .manageEntry{
          }
        }
      }
    }
  }
`