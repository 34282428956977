import { AppShell } from "@mantine/core"
import { SideBar } from "./Sidebar"

export const Dashboard = ( props ) => {

  return (
    <AppShell
      padding={[15]}
      navbar={<SideBar/>}
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1] },
      })}
    >
      { props.children }
    </AppShell>
  )
}