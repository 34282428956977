import axios from "axios";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import {
  Button,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ProgramCard } from "./ProgramCard";

export const CreatedPrograms = ({ props }) => {
  const { type } = props;

  const { user } = useContext(AppContext);

  const endpoint =
    type === "course"
      ? `api/usercourselist/${user.id}/`
      : type === "hackathon"
      ? `hackathon/hackathonbyuserid/${user.id}`
      : `internship/userinternshiplist/${user.id}/`;


  const [createdContentAmount, setCreatedContentAmount] = useState(7);

  const { data: createdContent, isLoading } = useQuery(
    [
      type === "course"
        ? "created courses"
        : type === "hackathon"
        ? "created hackathons"
        : "created internships",
    ],
    async () => await axios.get(endpoint).then((res) => res.data)
  );


  return (
    <Container p={0} fluid>
      <LoadingOverlay
        color="yellow"
        visible={isLoading}
        loaderProps={{ size: "lg", color: "yellow.4", variant: "bars" }}
      />

      <Button
        fullWidth
        color={"dark"}
        leftIcon={<Plus />}
        component={Link}
        to={
          type === "course"
            ? "/course/create"
            : type === "hackathon"
            ? "/hackathon/create"
            : "/internship/create"
        }
      >
        Create a new {type}
      </Button>

      <Container p={0} my={30} fluid>
        <Text size={20} weight={600}>
          Published {type}s
        </Text>
        <Group my={20}>
          {createdContent?.slice(0, createdContentAmount)?.map((content) => {
            return (
              content?.is_shared === true && (
                <ProgramCard
                  key={content.id}
                  props={{
                    type: "link",
                    title: content?.title,
                    image: content?.image,
                    link:
                      type === "course"
                        ? `/course/${content.id}`
                        : type === "hackathon"
                        ? `/hackathon/${content.id}`
                        : `/internship/${content.id}`,
                  }}
                />
              )
            );
          })}
        </Group>
        <Center>
          {createdContent && createdContent[createdContentAmount] && (
            <Button
              color={"dark"}
              onClick={() => setCreatedContentAmount(createdContentAmount + 8)}
            >
              See more
            </Button>
          )}
        </Center>
      </Container>

      <Container p={0} my={30} fluid>
        <Text size={20} weight={600}>
          Unpublished {type}s
        </Text>
        <Group my={20}>
          {createdContent?.slice(0, createdContentAmount)?.map((content) => {
            return (
              content?.is_shared === false && (
                <ProgramCard
                  key={content.id}
                  props={{
                    type: "link",
                    title: content?.title,
                    image: content?.image,
                    link:
                      type === "course"
                        ? `/course/${content.id}`
                        : type === "hackathon"
                        ? `/hackathon/${content.id}`
                        : `/internship/${content.id}`,
                  }}
                />
              )
            );
          })}

        </Group>
        <Center>
          {createdContent && createdContent[createdContentAmount] && (
            <Button
              color={"dark"}
              onClick={() => setCreatedContentAmount(createdContentAmount + 8)}
            >
              See more
            </Button>
          )}
        </Center>
      </Container>
    </Container>
  );
};
