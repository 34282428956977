import axios from "axios";
import styled from "styled-components";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Avatar, Button, Table } from "@mantine/core";
import { Link } from "react-router-dom";
import { ReportFilter } from "./ReportFilter";
import {
  AdjustmentsAlt,
  ChevronLeft,
  ChevronRight,
  Refresh,
  TableExport,
} from "tabler-icons-react";
import * as XLSX from "xlsx";

export const Reports = ({ props }) => {
  const { program } = props;

  const [reportData, setReportData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  let link =
    program === "taskilled"
      ? "/reports/users_order_internship_for_admin/"
      : "/reports/users_order_tip_for_admin/";

  const { isLoading } = useQuery(
    [`reports`],
    async () => await axios.get(link).then((res) => res.data),
    {
      onSuccess: (data) => {
        setReportData(data);
      },
    }
  );

  const { mutate: reFetch, isLoading: refetching } = useMutation(
    [`reports refetch`],
    async () => await axios.get(link).then((res) => res.data),
    {
      onSuccess: (data) => {
        setReportData(data);
      },
    }
  );

  const { mutate: fetchPage, isLoading: pageFetchLoading } = useMutation(
    [`reports page`],
    async (link) => {
      let linkWithS = link.replace("http", "https");
      return await axios.get(linkWithS).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        setReportData(data);
      },
    }
  );

  // // console.log(reportData?.results?.map(data =>
  // //   data.skill
  // ))

  function exportData() {
    let arrayX = [];

    reportData?.results?.map((data) => {
      arrayX.push({
        Person: `${data?.user?.first_name} ${data?.user?.last_name}`,
        Phone: data?.user?.number,
        Email: data?.user?.email,
        Speciality: data?.user?.speciality?.content,
        Program: data?.intershipinfo?.title,
        Price: data?.price,
        Date: data?.date,
        "Promo Code": data?.promocode,
        Skills: [
          data?.skill?.map((s, i) =>
            i === 0 ? s?.skill.skill : " " + s?.skill.skill
          ),
        ][0].toString(),
      });
    });

    console.log("🚀 | exportData | arrayX:", arrayX);

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(arrayX);

    XLSX.utils.book_append_sheet(wb, ws, "report data");

    XLSX.writeFile(wb, "Reports.xlsx");
  }

  const userArray =
    reportData?.results &&
    reportData?.results.map((data) => {
      return (
        <tr key={data?.id}>
          {/* <td style={{ padding:"1rem 0", display:"flex", alignItems:"center", gridGap:"0.5rem", color:"#000" }}>
          <Button color={"teal"} mr={8} p={8} radius="sm" ><Plus/></Button>
          <div className="userCode">
            <Text color={"dimmed"}>#</Text>
            <Text>6604985920</Text>
          </div>
        </td> */}
          <td
            style={{
              display: "flex",
              alignItems: "center",
              gridGap: "0.75rem",
              color: "#000",
            }}
          >
            <Avatar size={50} src={data?.user?.profile_img} alt="User" />
            <Link to={`/profile/${data?.user?.id}`} style={{ color: "#000" }}>
              <p>
                {data?.user?.first_name} {data?.user?.last_name}
              </p>
            </Link>
          </td>
          <td>
            <p>{data?.user?.speciality?.content || " "}</p>
          </td>
          <td>
            <p>
              {data?.skill &&
                data.skill.map((skill, index) => {
                  if (index < 2) {
                    return `${
                      `${index !== 0 ? ", " : " "}` +
                      skill?.skill.skill +
                      (index === 1 && data?.skill.length > 2 ? "..." : "")
                    }`;
                  } else return null;
                })}
            </p>
          </td>
          <td>
            <p>{data?.user?.email}</p>
          </td>
          <td>
            <p>{data?.user?.number}</p>
          </td>
          <td>
            <p>{data?.intershipinfo?.title}</p>
          </td>
          <td>
            <p>{data?.date}</p>
          </td>
          <td>
            <p>{data?.price}</p>
          </td>
          <td>
            <p>{data?.grade}</p>
          </td>
          <td>
            <p>{data?.promocode}</p>
          </td>
        </tr>
      );
    });

  function ReportNavigation() {
    return (
      <div className="navigate">
        <Button
          loading={refetching || isLoading || pageFetchLoading}
          variant="outline"
          color="dark"
          radius="sm"
          fullWidth
          leftIcon={<AdjustmentsAlt />}
          onClick={() => setFilterOpen(true)}
        >
          Filter
        </Button>
        <Button
          variant="outline"
          color="dark"
          leftIcon={<ChevronLeft />}
          disabled={reportData?.previous === null}
          onClick={() => fetchPage(reportData?.previous)}
        >
          Prev
        </Button>
        <Button
          variant="outline"
          color="dark"
          rightIcon={<ChevronRight />}
          disabled={reportData?.next === null}
          onClick={() => fetchPage(reportData?.next)}
        >
          Next
        </Button>
        <Button
          leftIcon={<TableExport />}
          color={"gray.7"}
          onClick={() => exportData()}
        >
          Export
        </Button>
      </div>
    );
  }

  return (
    <ReportStyle>
      <div className="reportHead">
        <h2>Reports</h2>
        <div>
          <h3>Entries: {reportData?.count}</h3>
          <Button onClick={() => reFetch()} variant="default">
            <Refresh />
          </Button>
        </div>
      </div>

      <ReportNavigation />

      <div className="users">
        <div className="reportData">
          <Table className="reportTable">
            <thead>
              <tr>
                <th>User profile</th>
                <th>Speciality</th>
                <th>Skill</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Internships</th>
                <th>Date</th>
                <th>Price</th>
                <th>Grade</th>
                <th>Promocode</th>
              </tr>
            </thead>
            <tbody className="tableEntries">{userArray}</tbody>
          </Table>
        </div>
      </div>

      <ReportFilter
        props={{
          program: "taskilled",
          filterOpen,
          setFilterOpen,
          reFetch,
          setReportLoading,
          reportData,
          setReportData,
        }}
      />
    </ReportStyle>
  );
};

const ReportStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  hr {
    border: none;
    border-bottom: 1px solid #bbb;
  }
  .reportHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #f8f9fa; */
    /* padding: 0 .5rem; */
    border-radius: 0.25rem;
    h2,
    h3 {
      font-weight: 500;
      margin: 0;
    }
    div {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
    }
  }
  .navigate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    /* margin: 1rem; */
    button {
      font-weight: 500;
    }
  }
  .reportTable {
    .tableEntries {
      p {
        min-width: max-content;
      }
    }
  }
  .userCode {
    display: flex;
    grid-gap: 0.25rem;
  }
  .users {
    .wishlist {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 1rem;
      background-color: #e9ecef;
      display: flex;
      flex-direction: column;
      grid-gap: 0.75rem;
      h3 {
        font-weight: 500;
        margin: 0;
      }
      li {
        list-style: none;
        background: #dee2e6;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
      }
    }
  }
  .reportData {
    width: 99%;
    max-width: 99%;
    margin: 0 auto;
    height: 620px;
    max-height: 620px;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      /* margin-top: 1rem; */
    }

    ::-webkit-scrollbar-track {
      margin-top: 2.2rem;
      border-radius: 1rem;
      background: rgba(126, 126, 126, 0);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: rgba(126, 126, 126, 0.5);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(126, 126, 126, 1);
    }
  }
`;
