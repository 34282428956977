import { Minus, Plus } from "tabler-icons-react"
import { Button, Input,Select } from "@mantine/core"

export function CreateQuestion({props}){

  const { pendingQuestion, setPendingQuestion, variants, setVariants, questions, setQuestions, defaultVariants } = props

  function reduceAmount(){
    if(variants.length > 2){
      setVariants(variants.slice(0, -1))
    }
  }

  function addAmount(){
    if(variants.length < 5){
      setVariants(variants.concat(defaultVariants[variants.length]))
    }
  }

  function appendToExisting(e){
    e.preventDefault()

    setQuestions([
      ...questions,
      pendingQuestion
    ])

    setPendingQuestion({
      question:"",
      options: { "A": "", "B": "", "C": "", "D": "", "E": "" },
      correctAnswer: ""
    })
  }

  return(
    <div className="pending">
      <h3>Create question</h3>
      <Input.Wrapper type="text" label="Question" required>
        <Input type="text"
          required
          placeholder="Question"
          value={pendingQuestion.question}
          onChange={(e) => setPendingQuestion(
            {
              ...pendingQuestion,
              question: e.target.value
            }
          )}
        />
      </Input.Wrapper>
      <br />
      <Input.Wrapper label="Options" required>
        <div className="buttons">
          <Button type="button" variant="subtle"  disabled={variants.length > 2 ? false : true} onClick={() => reduceAmount()}><Minus/></Button>
          <Button type="button" variant="subtle"  disabled={variants.length < 5 ? false : true} onClick={() => addAmount()}><Plus/></Button>
        </div>
        {
          variants.map((key, index) => {
            return(
              <div key={index} className="options">
                <p>{key + ":"}</p>
                <Input type="text"
                  required
                  placeholder={`Option ${key}`}
                  value={pendingQuestion?.options?.[key]}
                  onChange={(e) => setPendingQuestion(
                    {
                      ...pendingQuestion,
                      options: {
                        ...pendingQuestion?.options,
                        [key]: e.target.value
                      }
                    }
                  )}
                />
              </div>
            )
          })
        }
      </Input.Wrapper>
      <br />
      <Select
        // required
        label="Correct answer"
        placeholder="Choose the correct answer"
        clearable
        data={
          variants.map((key, index) => ({
            value: key,
            label: key
          }))
        }
        value={pendingQuestion?.correctAnswer}
        onChange={(e) => setPendingQuestion(
          {
            ...pendingQuestion,
            correctAnswer: e
          }
        )}
      />
      <br />
      <Button onClick={(e) => appendToExisting(e)} type={pendingQuestion?.correctAnswer ? "submit" : "button"} variant={pendingQuestion?.correctAnswer ? "gradient" : "filled"} color="gray" gradient={{ from: "teal", to: "lime", deg: 105 }}>Append</Button>
    </div>
  )
}