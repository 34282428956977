import axios from "axios";
import { useState } from "react";
import { EditSyllabus } from "./EditSyllabus/_EditSyllabus";
import { SyllabusStyle } from "./SyllabusStyle";
import { SyllabusInfo } from "./SyllabusInfo";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "@mantine/core";
// import certificate from "../../../Assets/Images/certificate.png";
// import PDFCert from "../../Certification";

export const ProgramSyllabus = ({props}) => {

  const { type, user, entryData } = props;

  const [ syllabus, setSyllabus ] = useState([]);
  const [ showCertificate, setShowCertificate ] = useState(false);
  const [ showEditModal, setShowEditModal ] = useState(false);

  async function fetchSyllabus(){
    if(entryData?.id) {
      const response = await axios.get(type === "course" ? `/syllabus/coursesyllabus/list/${entryData?.id}/` : `/syllabus/internshipsyllabus/list/${entryData?.id}/`);

      if(response.status === 200) {
        setSyllabus(response.data);
      }
    }
  }

  useQuery(["syllabus", entryData?.id, showEditModal],
    async () => {
      return await axios.get(type === "course" ? `/syllabus/coursesyllabus/list/${entryData?.id}/` : `/syllabus/internshipsyllabus/list/${entryData?.id}/`).then(response => { return response.data });
    },
    {
      onSuccess: (data) => {
        setSyllabus(data);
      }
    }
  )

  return (
    <SyllabusStyle>
      {/* {
        showCertificate &&
        <PDFCert props={{ setShowCertificate, username:`${user.first_name} ${user.last_name}`, programName:entryData?.title, programPartners:entryData?.partners }}/>
      } */}
      {/* <Modal opened={showCertificate} onClose={() => setShowCertificate(false)} size="50%" withCloseButton={false}>
        <img style={{ width:"100%" }} src={certificate} alt="certificate" />
      </Modal> */}
      {
        showEditModal &&
        <EditSyllabus props={{ type, fetchSyllabus, entryData, syllabus, setSyllabus, setShowEditModal }}/>
      }
      <SyllabusInfo props={{ type, setShowCertificate, user, entryData, syllabus, showEditModal, setShowEditModal }} />
    </SyllabusStyle>
  )
}