import { NewSection as NewSectionIcon } from "tabler-icons-react";

export function SectionList({ props }) {
  const {
    pendingContent,
    setChangeSectionModal,
    setSectionContentFiltered,
    fetchSection,
    otherSections,
    sectionContent,
    setAddSectionModalOpen,
  } = props;

  function changeSection(id) {
    if (pendingContent.length > 0) {
      setChangeSectionModal(id);
    } else {
      setSectionContentFiltered([]);

      fetchSection(id);
    }
  }

  return (
    <div className="sectionsList">
      <h3>Sections</h3>
      <div className="wrapper">
        <div className="inside">
          {otherSections.map((section) => {
            return (
              <div key={section.id}>
                <button
                  onClick={() => changeSection(section.id)}
                  className={
                    sectionContent[0]?.section?.id == section?.id ||
                    sectionContent?.id == section?.id
                      ? "chosenSection"
                      : ""
                  }
                  key={section.id + section.title}
                >
                  {section.title}
                </button>
                <ins></ins>
              </div>
            );
          })}
        </div>
        <button
          className="addSection"
          onClick={() => setAddSectionModalOpen(true)}
        >
          <NewSectionIcon />
          Add another section
        </button>
      </div>
    </div>
  );
}
