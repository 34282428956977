import { Card, Image, Text, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

export function ProgramCard({ props }) {

  const { link, image, title, type } = props;

  return (
    <Card component={type === "link" ? Link : ""} to={link} shadow="sm" p="lg" radius="md" withBorder style={{ minWidth:"292px", maxWidth:"292px" }}>
      <Card.Section>
        <Image
          src={image?.name ? URL.createObjectURL(image) : image}
          height={200}
          alt="Card"
          withPlaceholder
        />
      </Card.Section>

      <Group position="apart" mt="md" mb="0">
        <Text size={16} weight={600}>{ title?.length > 48 ? title.substring(0,48) + "..." : title || "Type something"}</Text>
      </Group>
    </Card>
  );
}