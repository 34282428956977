import styled from "styled-components";
import { Avatar, Image, Spoiler, Text } from "@mantine/core";
import { ChevronDown, ChevronUp } from "tabler-icons-react";

export const ProfileIntro = ({ props }) => {

  const { userData:user } = props;

  return (
    <IntroStyle>
      <div className="wrapperIntro">
        {
          user?.profile_img?.endsWith("download.png") ?
          <Avatar
            className="avatar"
            src={""}
            size={100}
            radius={100}
          />
          :
          <Avatar
            className="avatar"
            src={user?.profile_img}
            size={100}
            radius={100}
          />
        }
        <div className="title">
          <div>
            <Text size={23}>{user?.first_name} {user?.last_name !== "." && user?.last_name}</Text>
            {
              user?.speciality?.content &&
              <Text color={"dimmed"}>{user?.speciality?.content || "Speciality not chosen"}</Text>
            }
          </div>
        </div>
      </div>
      {
        user?.bio &&
        <Spoiler maxHeight={120} showLabel={<ChevronDown size={30}/>} hideLabel={<ChevronUp size={30}/>} style={{marginTop: user?.bio?.length > 160 ? "-.75rem" : ""}}>
          <Text mt={10}>{user?.bio}</Text>
        </Spoiler>
      }
    </IntroStyle>
  );
};

const IntroStyle = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  border-radius: .75rem;
  border: 1px solid #DEE2E6;
  .wrapperIntro{
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    .title{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
    .wrapperIntro{
      width: 92%;
    }
  }
`;