import axios from "axios"
import { useState, useEffect } from "react"
import { DatePicker, DateRangePicker } from "@mantine/dates"
import { useMutation, useQuery } from "@tanstack/react-query"
import { SkeletonLoader } from "../../Components/Loaders/SkeletonLoader"
import { Rotate, Search } from "tabler-icons-react"
import { Button, Divider, Modal, NumberInput, Select, Space, Text, TextInput } from "@mantine/core"

export function TalantFilter({ props }){

  const { program, reFetch, setReportData, setReportLoading } = props
  
  const [ programTypeChosen, setProgramTypeChosen ] = useState(null)
  const [ resetForm, setResetForm ] = useState(false)

  const { data:specialities } = useQuery([`specialities`],
    async () => await axios.get(`/api/v1/speciality/`).then(res => res.data)
  )

  const { data:skillsList } = useQuery(["skillsList"],
    async () => { return await axios.get(`/api/skill_list/`).then(res => res.data) },
  )

  let domain = ""
  
  if(program === "taskool"){
    domain = "/reports/users_order_tip_for_admin/"
  }

  if(program === "taskilled" && programTypeChosen === "course"){
    domain = "/reports/users_order_course_for_admin/"
  }
  
  if(program === "taskilled" && programTypeChosen === "intershipinfo"){
    domain = "/reports/users_order_internship_for_admin/"
  }
  
  const { data:programChosen } = useQuery([`${programTypeChosen}`],
    async () => await axios.get(programTypeChosen === "course" ? `/api/all-courses/` : `/internship/activeinternshiplist/`).then(res => res.data)
  )
  
  const { mutate:searchData, isLoading } = useMutation([`searchData`],
    async (e) => {
      e.preventDefault()

      let { skill, programType, program, firstName, lastName, grade, dateRange, specificDate, payment, speciality, promocode, ulduzum, taskCompleted  } = e.target

      let linksObj = {
        programTypeChosen: programType?.value && `${programType?.value}=${program?.value}&`,
        firstNameLink: firstName?.value && `first_name=${firstName?.value}&`,
        gradeLink: grade?.value && `grade=${grade?.value}&`,
        lastNameLink: lastName?.value && `last_name=${lastName?.value}&`,
        paymentLink: payment?.value === "1" ? `successfuly_paid=True&` : payment?.value === "2" ? `is_free=True&` : "",
        specialityLink: speciality?.value && `speciality=${speciality?.value}&`,
        startDateLink: dateRange?.value && `begin_date=${dateRange?.value.substring(0, 10)}&`,
        endDateLink: dateRange?.value && `end_date=${dateRange?.value.substring(13, 23)}&`,
        specificDateLink: specificDate?.value && `date=${specificDate?.value}`,
        promocodeLink: promocode?.value && `promocode=${promocode?.value}`,
        ulduzumLink: ulduzum?.value === "1" ? `is_campaign=True` : ulduzum?.value === "2" ? `is_campaign=False` : "",
        taskCompletedLink: taskCompleted?.value === "1" ? `task_completed=True` : taskCompleted?.value === "2" ? `task_completed=False` : "",
        skillLink: skill?.value && `skill=${skill?.value}`,
      }

      let { skillLink, programTypeChosen, firstNameLink, gradeLink, lastNameLink, paymentLink, specialityLink, startDateLink, endDateLink, specificDateLink, promocodeLink, ulduzumLink, taskCompletedLink } = linksObj

      let response = await axios.get(`${domain}?${skillLink !== undefined ? skillLink : ""}${paymentLink !== undefined ? paymentLink : ""}${programTypeChosen !== undefined ? programTypeChosen : ""}${firstNameLink !== undefined ? firstNameLink : ""}${lastNameLink !== undefined ? lastNameLink : ""}${specialityLink !== undefined ? specialityLink : ""}${gradeLink !== undefined ? gradeLink : ""}${startDateLink !== undefined ? startDateLink : ""}${endDateLink !== undefined ? endDateLink : ""}${specificDateLink !== undefined ? specificDateLink : ""}${promocodeLink !== undefined ? promocodeLink : ""}${ulduzumLink !== undefined ? ulduzumLink : ""}${taskCompletedLink !== undefined ? taskCompletedLink : ""}`
      ).then(res => res.data)

      setReportData(response)
    },
    { 
      onSuccess: () => {
        setReportLoading(false)
      }
    }
  )

  function resetSearch(){
    setResetForm(true)
    // setFilterOpen(false)
    setTimeout(() => {
      setResetForm(false)
      reFetch()
    }, 250);
  }

  if(resetForm){
    return(
      <SkeletonLoader height={267} my={15} visible={true}/>
    )
  }

  return(
    // <Modal
    //   opened={filterOpen}
    //   onClose={() => setFilterOpen(false)}
    //   withCloseButton={false}
    //   size="1000px"
    //   overlayColor="#aaaaaa99"
    // >

      <div className="filterStyle" style={{ backgroundColor:"#fefefe", border:"1px solid #aaa", borderRadius:"0.25rem", padding:"1rem" }}>
        <form onSubmit={(e) => searchData(e)} id="searchForm">

          {/* <Divider size={2} label="FILTER" labelPosition="center" labelProps={{ size:20, weight:600, color:"dimmed" }}/> */}

          <div className="talantWrapper">

            <div className="group">
              <Select
                clearable
                name="programType"
                label="Program type"
                placeholder="Pick a program type"
                searchable
                data={["Course", "Internship"]?.map(program => { 
                  if(program === "Course"){
                    return { value:"course", label:"Course" } 
                  }
                  if(program === "Internship"){
                    return { value:"intershipinfo", label:"Internship" } 
                  }
                })}
                onChange={(e) => setProgramTypeChosen(e)}
              />

              <Select
                disabled={programTypeChosen === null}
                clearable
                name="program"
                label="Program"
                placeholder="Pick a program"
                searchable
                data={programChosen ? programChosen?.map(program => { return { value: program.id, label: program.title } } ) : []}
              />

            </div>

            <Space my={15} />

            <div className="group">

              <Select
                disabled={programTypeChosen === null}
                name="education"
                searchable
                clearable
                label="Education"
                placeholder="Search for facilities"
                data={[]}
              />

              <Select
                disabled={programTypeChosen === null}
                clearable
                name="industry"
                label="Industry"
                placeholder="Pick an industry"
                searchable
                data={specialities ? specialities?.map(speciality => { return { value: speciality?.id, label: speciality?.content} } ) : []}
              />

              <NumberInput
                disabled={programTypeChosen === null}
                min={0}
                clearable
                name="experience"
                label="Experience"
                placeholder="Number of years"
                searchable
                data={[]}
              />

              {/* <Select
                disabled={programTypeChosen === null}
                name="certs"
                searchable
                clearable
                label="Certifications"
                placeholder="Search for certifications"
                data={[]}
              /> */}

            </div>

          </div>

          <Divider mt={25} label="Skills" labelProps={{ size:18, weight:600, color:"gray" }}/>
          <div className="group">
            <Select
              disabled={programTypeChosen === null}
              name="skill"
              searchable
              clearable
              label="Soft skills"
              placeholder="Search for soft skills"
              data={ skillsList ? skillsList?.map(skill => { return { value: skill?.id, label: skill?.skill, group: skill?.header?.header } }) : [] }
            />

            <Select
              disabled={programTypeChosen === null}
              name="languages"
              searchable
              clearable
              label="Language"
              placeholder="Search for languages"
              data={[]}
            />

            <Select
              disabled={programTypeChosen === null}
              name="technicals"
              searchable
              clearable
              label="Technical skills"
              placeholder="Search for technical skill"
              data={[]}
            />
          </div>

          <Divider mt={25} label="Open to work" labelProps={{ size:18, weight:600, color:"gray" }}/>
          <div className="group">
            <Select
              disabled={programTypeChosen === null}
              name="industry"
              searchable
              clearable
              label="Industry"
              placeholder="Search for industries"
              data={ skillsList ? skillsList?.map(skill => { return { value: skill?.id, label: skill?.skill, group: skill?.header?.header } }) : [] }
            />

            <Select
              disabled={programTypeChosen === null}
              name="location"
              searchable
              clearable
              label="Locations"
              placeholder="Search for locations"
              data={[]}
            />

            <DatePicker
              disabled={programTypeChosen === null}
              name="startDate"
              searchable
              clearable
              label="Date"
              placeholder="Pick the start date"
              data={[]}
            />

            <NumberInput
              disabled={programTypeChosen === null}
              min={0}
              name="salary"
              searchable
              clearable
              label="Hourly salary"
              placeholder="Pick hourly salary"
              data={[]}
            />

            <Select
              disabled={programTypeChosen === null}
              name="employment"
              searchable
              clearable
              label="Employment"
              placeholder="Pick employment type"
              data={["Full-time", "Part-time", "Internship"]}
            />

            <Select
              disabled={programTypeChosen === null}
              name="workPlace"
              searchable
              clearable
              label="Work place"
              placeholder="Pick the work condition"
              data={["On-site", "Remote"]}
            />

          </div>

          <div className="buttons">
            <Button disabled={programTypeChosen === null} leftIcon={<Rotate size={18}/>} type="button" variant="outline" color="dark" onClick={(e) => {}}>Reset</Button>
            <Button loading={isLoading} disabled={programTypeChosen === null} leftIcon={<Search size={18}/>} type="submit" variant="filled" color="dark">Search</Button>
          </div>
        </form>
      </div>
    // </Modal>
  )
}