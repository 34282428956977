import { useContext } from "react";
import { AppContext } from "./Context";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Auth
import { Login } from "../Pages/Authentication/Login/Login";
import Register from "../Pages/Authentication/Register/_Register";
import ResetPasswordSuccess from "../Pages/Authentication/ResetPassword/_ResetPassword";

import PublicProfilePage from "../Pages/PublicProfile/_Profile";

import { Dashboard } from "../Components/Dashboard";
import { Reports } from "../Pages/Reports/Reports";
import { People } from "../Pages/People/People";

// Programs
import { CreateProgram } from "../Components/CreateProgram/_UploadCourse";
import { CreatedPrograms } from "../Components/CreatedPrograms";
import { Program } from "../Components/ProgramInfo/_Program";
import { EditProgramInfo } from "../Components/ProgramInfo/EditProgramInfo/EditProgramInfo";
import { ContentEditor } from "../Components/ContentEditor/_ContentEditor";
import { EmptyDash } from "../Components/EmptyDash";
import { SoftSkillsForms } from "../Pages/SoftSkills/SoftSkillsForms";
import { SoftSkillsCreate } from "../Pages/SoftSkills/SoftSkillsCreate";
import { SoftSkills } from "../Pages/SoftSkills/SoftSkills";

import { Hackathons } from "../Pages/Hackathon/Hackathons";
import ModuleExplorer from "../Pages/ModuleExplorer/ModuleExplorer";
import Users from "../Pages/Users/Users";

export function RouteLogic() {
  let { user } = useContext(AppContext);

  return (
    <BrowserRouter>
      {user?.id ? (
        <Dashboard>
          <PrivatePages props={{ user }} />
        </Dashboard>
      ) : (
        <PublicPages />
      )}
    </BrowserRouter>
  );
}

function PublicPages() {
  return (
    <Routes>
      {/* Site Auth */}
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />
    </Routes>
  );
}

function PrivatePages({ props }) {
  const { user } = props;

  return (
    <Routes>
      <Route path="/" element={<EmptyDash />} />

      {user?.id === 25 && (
        <>
          <Route
            path="/talants"
            element={<People props={{ program: "taskilled" }} />}
          />
          <Route
            path="/reports"
            element={<Reports props={{ program: "taskilled" }} />}
          />
          <Route path="/hackathon/created" element={<Hackathons />} />
          <Route path="/modulemanagement" element={<ModuleExplorer />} />
          <Route path="/users" element={<Users />} />
        </>
      )}

      <Route
        path="/profile/:id"
        element={<PublicProfilePage props={{ isTaskool: false }} />}
      />

      {/* Internships */}
      <Route
        path="/internship/:id"
        element={<Program props={{ isTaskool: false, type: "internship" }} />}
      />
      <Route
        path="/internship/edit/:id"
        element={<EditProgramInfo props={{ type: "internship" }} />}
      />
      <Route
        path="/internship/add/:id"
        element={<ContentEditor props={{ entryType: "internship" }} />}
      />
      <Route
        path="/internship/create"
        element={<CreateProgram props={{ type: "internship" }} />}
      />
      <Route
        path="/internship/created"
        element={<CreatedPrograms props={{ type: "internship" }} />}
      />

      {/* Courses */}
      <Route
        path="/course/:id"
        element={<Program props={{ isTaskool: false, type: "course" }} />}
      />
      <Route
        path="/course/edit/:id"
        element={<EditProgramInfo props={{ type: "course" }} />}
      />
      <Route
        path="/course/add/:id"
        element={<ContentEditor props={{ entryType: "course" }} />}
      />
      <Route
        path="/course/create"
        element={<CreateProgram props={{ type: "course" }} />}
      />
      <Route
        path="/course/created"
        element={<CreatedPrograms props={{ type: "course" }} />}
      />

      {/* Hackathons */}
      {/* <Route
        path="/hackathon/:id"
        element={<Program props={{ isTaskool: false, type: "hackathon" }} />}
      />
      <Route
        path="/hackathon/edit/:id"
        element={<EditProgramInfo props={{ type: "hackathon" }} />}
      />
      <Route
        path="/hackathon/add/:id"
        element={<ContentEditor props={{ entryType: "hackathon" }} />}
      />
      <Route
        path="/hackathon/create"
        element={<CreateProgram props={{ type: "hackathon" }} />}
      /> */}

      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />
    </Routes>
  );
}
