import axios from 'axios'
import { useEffect } from 'react'
import styled from "styled-components"
import { Button, Input,Select } from '@mantine/core'
import { ArrowDown, ArrowUp } from 'tabler-icons-react'

export const QuestionsReadyToPost = ({props}) => {
  const { fetchSection, quizId, quizName, sectionIdX, questions, setQuestions, currentEditing, setCurrentEditing, variants } = props

  function changePosition(index){
    const newQuestions = [...questions]
    const [ current ] = newQuestions.splice(index, 1)
    newQuestions.splice(index + 1, 0, current)
    setQuestions(newQuestions)
  }

  function moveUp(index){
    if(index > 0){
      changePosition(index - 1)
    }
  }

  function moveDown(index){
    if(index < questions.length - 1){
      changePosition(index)
    }
  }

  useEffect(() => {
    if(quizId){
      questions.forEach(async (entry, i) => {
        let questionId = null;
        if(entry.question){

          let formX = new FormData();
          formX.append("name", entry.question);
          formX.append("quiz", quizId);
          formX.append("point", 5);

          let questionPost = await axios.post("/quiz/question/", formX)
          questionId = questionPost?.data?.data?.id;
        }
        if(entry.options && questionId){
          Object.entries(entry.options).forEach(async (variant, i) => {

            let formX = new FormData();
            formX.append("name", variant[1]);
            formX.append("question", questionId);
            formX.append("is_correct", variant[0] == entry.correctAnswer);
            formX.append("explanation", "pass");

            if(variant[1]){
              let answerPost = await axios.post("/quiz/option/", formX)
            }
          })
        }
        setQuestions([])

        if(questions.length === i + 1){
          fetchSection(sectionIdX)
        }
      })
    }
    return () => {
      setQuestions([])
    }
  }, [quizId]);


  return(
    <ExistingStyle>
      <h3>Pending questions</h3>
      {
        questions.map((entry, index) => {
          return (
            <div key={index} className="pendingQuestions">
              {
                index > 0 &&
                <hr />
              }
              <div className='manageQuestion'>
                <Button variant='outline' disabled={index > 0 ? false : true} onClick={() => moveUp(index)}>{<ArrowUp/>}</Button>
                <Button variant='outline' disabled={index < questions.length - 1 ? false : true} onClick={() => moveDown(index)}>{<ArrowDown/>}</Button>
              </div>
              {
                currentEditing === index ?
                <form >
                  <Input.Wrapper type="text" label="Question" required>
                    <Input type="text"
                      required
                      placeholder="Question"
                      value={questions[index].question}
                      onChange={(e) => {
                        setQuestions(questions.map((entry, i) => i === index ? {...entry, question: e.target.value} : entry))
                      }}
                    />
                  </Input.Wrapper>
                  <ul>
                    {
                      Object.keys(entry.options).map((variant, i) => {
                        return (
                          questions[index].options[variant] &&
                          <div key={index} className="options">
                            <p>{variant + ":"}</p>
                            <Input type="text"
                              required
                              value={questions[index].options[variant]}
                              onChange={(e) => {
                                setQuestions(questions.map((entry, i) => i === index ? {...entry, options: {...entry.options, [variant]: e.target.value}} : entry))
                              }}
                            />
                          </div>
                        )
                      })
                    }
                  </ul>
                  <Select
                    required
                    label="Correct answer"
                    placeholder="Choose the correct answer"
                    clearable
                    data={
                      variants.map((variant, i) => {
                        return {
                          value: variant,
                          label: variant
                        }
                      }
                    )}
                    value={questions[index].correctAnswer}
                    onChange={(e) => {
                      setQuestions(questions.map((entry, i) => i === index ? {...entry, correctAnswer: e} : entry))
                    }}
                  />
                  <br />
                </form>
                :
                <div>
                  <h3>Question: {entry?.question}</h3>
                  <ul>
                    {
                      Object.keys(entry?.options).map(key => (

                        entry?.options[key] && <li key={key}>{key}: {entry?.options[key]}</li>
                      ))
                    }
                  </ul>
                  <p>Correct answer: {entry?.correctAnswer}</p>
                </div>
              }
              {
                currentEditing === index ?
                <div className='finishEdit manageQuestion'>
                  <Button color={'green'} onClick={() => {setCurrentEditing(null)}}>Finish editing</Button>
                  <Button color={'red'} onClick={() => {setQuestions(questions.filter((entry, i) => i !== index))}}>Remove question</Button>
                </div>
                :
                <div className='manageQuestion'>
                  <Button color={'green'} onClick={() => {setCurrentEditing(index)}}>Edit question</Button>
                  <Button color={'red'} onClick={() => {setQuestions(questions.filter((entry, i) => i !== index))}}>Remove question</Button>
                </div>
              }
            </div>
          )
        })
      }
    </ExistingStyle>
  )
}

const ExistingStyle = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  .pendingQuestions{
    hr{
      margin: 2rem 2rem 2rem 0;
      border: none;
      border-bottom: 1px solid #ccc;
    }
    .manageQuestion{
      margin-bottom: 1rem;
      button{
        margin-right: 0.5rem
      }
    }
    form{
      max-width: 95%;
      input{
      }
      .options{
        display: flex;
        align-items: center;
        p{
          margin-right: 0.5rem;
        }
      }
    }
  }
`