import axios from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Select, Button, Table } from "@mantine/core";
import { SkillsFilter } from "./SoftSkillsFilter";
import { Refresh, TableExport } from "tabler-icons-react";
import * as XLSX from "xlsx";

export const SoftSkills = ({ props }) => {
  const { program } = props;

  const [answersData, setAnswersData] = useState({});
  const [skillsLoading, setSkillsLoading] = useState(false);
  const [formTitle, setFormTitle] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(1);
  const [formList, setFormList] = useState([]);
  const [link, setlink] = useState(`/skill/1`);

  // https://backend.taskilled.com/skill/list/

  const {} = useQuery(
    [`forms list`],
    async () => await axios.get("/skill/list").then((res) => res.data),
    {
      onSuccess: (data) => {
        data = data.data;
        setFormList(data);
      },
    }
  );

  const { isLoading } = useQuery(
    [`forms`],
    async () => await axios.get(link).then((res) => res.data),
    {
      onSuccess: (data) => {
        if (!data) return setAnswersData([]);

        setFormTitle(data.title);
        data = data.values;
        let resultFormated = [];
        Object.keys(data).map((key) => {
          let object = {};
          object[key] = [];
          const item = data[key];
          Object.keys(item).map((key2) => {
            object[key].push(item[key2]);
            resultFormated.push(object);
          });
        });
        setAnswersData(resultFormated);
      },
    }
  );

  const { mutate: reFetch, isLoading: refetching } = useMutation(
    [`Form refetch`],
    async (link) => {
      return await axios.get(link).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (!data) return setAnswersData([]);

        setFormTitle(data.title);
        data = data.values;
        let resultFormated = [];
        Object.keys(data).map((key) => {
          let object = {};
          object[key] = [];
          const item = data[key];
          Object.keys(item).map((key2) => {
            object[key].push(item[key2]);
            resultFormated.push(object);
          });
        });
        setAnswersData(resultFormated);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: fetchPage, isLoading: pageFetchLoading } = useMutation(
    [`answers page`],
    async (link) => {
      let linkWithS = link.replace("http", "https");
      return await axios.get(linkWithS).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (!data) return setAnswersData([]);

        setFormTitle(data.title);
        data = data.values;
        let resultFormated = [];
        Object.keys(data).map((key) => {
          let object = {};
          object[key] = [];
          const item = data[key];
          Object.keys(item).map((key2) => {
            object[key].push(item[key2]);
            resultFormated.push(object);
          });
        });
        setAnswersData(resultFormated);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchPage(link);
  }, [link]);

  function exportData() {
    let arrayX = [];

    answersData.map((item) => {
      Object.keys(item).map((key) => {
        item[key].map((question) => {
          arrayX.push({
            ["Form Title"]: formTitle,
            ["Question list"]: String(key).replaceAll("_", " "),
            ["Question"]: question?.question,
            ["Answer 1 and point"]: `${question?.answer_1} (${question?.answer_1_point})`,
            ["Answer 2 and point"]: `${question?.answer_2} (${question?.answer_2_point})`,
            ["Answer 3 and point"]: `${question?.answer_3} (${question?.answer_3_point})`,
            ["Answer 4 and point"]: `${question?.answer_4} (${question?.answer_4_point})`,
          });
        });
      });
    });

    console.log("🚀 | exportData | arrayX:", arrayX);

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(arrayX);

    XLSX.utils.book_append_sheet(wb, ws, "Answers data");

    XLSX.writeFile(wb, "Answers.xlsx");
  }

  function ReportNavigation() {
    return (
      <div className="navigate">
        {/* <Button
          loading={refetching || isLoading || pageFetchLoading}
          variant="outline"
          color="dark"
          radius="sm"
          fullWidth
          leftIcon={<AdjustmentsAlt />}
          onClick={() => setFilterOpen(true)}
        >
          Filter
        </Button> */}
        {/* <Button
          variant="outline"
          color="dark"
          leftIcon={<ChevronLeft />}
          disabled={answersData?.previous === null}
          onClick={() => fetchPage(answersData?.previous)}
        >
          Prev
        </Button>
        <Button
          variant="outline"
          color="dark"
          rightIcon={<ChevronRight />}
          disabled={answersData?.next === null}
          onClick={() => fetchPage(answersData?.next)}
        >
          Next
        </Button> */}
        <Select
          // id="isQuestion"
          label="Select A Form"
          value={currentForm}
          onChange={(value) => {
            setlink(`/skill/${value}`);
            setCurrentForm(value);
          }}
          data={formList.map((form) => ({
            label: form.form_name,
            value: form.id,
          }))}
        />
        <Button
          leftIcon={<TableExport />}
          color={"gray.7"}
          onClick={() => exportData()}
        >
          Export
        </Button>
      </div>
    );
  }

  return (
    <SkillsStyle>
      <div className="reportHead">
        <h2>Answers</h2>
        <div>
          <h3>Entries: {answersData?.length}</h3>
          <Button onClick={() => reFetch(link)} variant="default">
            <Refresh />
          </Button>
        </div>
      </div>

      <ReportNavigation />

      <div className="users">
        <div className="answersData">
          <Table className="reportTable">
            <thead>
              <tr>
                <th>Form Title</th>
                <th>Question list</th>
                <th>Question</th>
                <th>Answer 1 and point</th>
                <th>Answer 2 and point</th>
                <th>Answer 3 and point</th>
                <th>Answer 4 and point</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tableEntries">
              {answersData.length &&
                answersData.map((item) => {
                  return Object.keys(item).map((key) => {
                    return item[key].map((question, i) => {
                      return (
                        <tr key={i}>
                          <td>{formTitle}</td>
                          <td>{String(key).replaceAll("_", " ")}</td>
                          <td>{question?.question}</td>
                          <td>
                            {question?.answer_1} ({question?.answer_1_point})
                          </td>
                          <td>
                            {question?.answer_2} ({question?.answer_2_point})
                          </td>
                          <td>
                            {question?.answer_3} ({question?.answer_3_point})
                          </td>
                          <td>
                            {question?.answer_4} ({question?.answer_4_point})
                          </td>
                          <td>
                            {question?.answer_4} ({question?.answer_4_point})
                          </td>
                        </tr>
                      );
                    });
                  });
                })}
            </tbody>
          </Table>
        </div>
      </div>

      <SkillsFilter
        props={{
          program: "taskilled",
          filterOpen,
          setFilterOpen,
          reFetch,
          setSkillsLoading,
          answersData,
          setAnswersData,
        }}
      />
    </SkillsStyle>
  );
};

const SkillsStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  hr {
    border: none;
    border-bottom: 1px solid #bbb;
  }
  .reportHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #f8f9fa; */
    /* padding: 0 .5rem; */
    border-radius: 0.25rem;
    h2,
    h3 {
      font-weight: 500;
      margin: 0;
    }
    div {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
    }
  }
  .navigate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    /* margin: 1rem; */
    button {
      font-weight: 500;
    }
  }
  .reportTable {
    .tableEntries {
      p {
        min-width: max-content;
      }
    }
  }
  .userCode {
    display: flex;
    grid-gap: 0.25rem;
  }
  .users {
    .wishlist {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 1rem;
      background-color: #e9ecef;
      display: flex;
      flex-direction: column;
      grid-gap: 0.75rem;
      h3 {
        font-weight: 500;
        margin: 0;
      }
      li {
        list-style: none;
        background: #dee2e6;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
      }
    }
  }
  .answersData {
    width: 99%;
    max-width: 99%;
    margin: 0 auto;
    height: 620px;
    max-height: 620px;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      /* margin-top: 1rem; */
    }

    ::-webkit-scrollbar-track {
      margin-top: 2.2rem;
      border-radius: 1rem;
      background: rgba(126, 126, 126, 0);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: rgba(126, 126, 126, 0.5);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(126, 126, 126, 1);
    }
  }
`;
