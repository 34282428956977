import axios from "axios";
import { Chip } from '@mantine/core';
import { Lock, LockOpen } from "tabler-icons-react";
import { showNotification } from '@mantine/notifications';

export function ProgramPrivacy({ props }){

  const { type, user, entryData, setEntryData } = props;

  async function handleEdit(privacyType){

    const formX = {};
    if(privacyType === "public"){
      formX.is_shared = true;
      formX.is_unlisted = false;
    }
    if(privacyType === "unlisted"){
      formX.is_shared = false;
      formX.is_unlisted = true;
    }
    if(privacyType === "private"){
      formX.is_shared = false;
      formX.is_unlisted = false;
    }

    const response = await axios.patch((type === "course" ? `/api/course/${entryData.id}/` : `/internship/${entryData.id}/`), formX)

    if(response.status === 200){
      if(privacyType === "private"){
        setEntryData({ ...entryData, is_shared: false, is_unlisted: false });
      }
      if(privacyType === "unlisted"){
        setEntryData({ ...entryData, is_shared: false, is_unlisted: true });
      }
      if(privacyType === "public"){
        setEntryData({ ...entryData, is_shared: true, is_unlisted: false });
      }
    }

    showNotification({
      icon: privacyType === "public" ? <LockOpen/> : privacyType === "unlisted" ? <LockOpen/> : <Lock/>,
      title: privacyType === "public" ? "Intenrship is now public" : privacyType === "unlisted" ? "Intenrship is now unlisted" : "Intenrship is now private",
      message: privacyType === "public" ? "Now everyone can see this intenrship" : privacyType === "unlisted" ? "Anyone with the link can see this intenrship" : "Now only you can see this intenrship",
      color: privacyType === "public" ? "teal" : privacyType === "unlisted" ? "blue" : "yellow",
    })

  }

  function returnType(){
    if((entryData?.is_shared === false && entryData?.is_unlisted === false)){
      return "private"
    }
    if((entryData?.is_shared === false && entryData?.is_unlisted === true)){
      return "unlisted"
    }
    if((entryData?.is_shared === true && entryData?.is_unlisted === false)){
      return "public"
    }
  }

  if(( (entryData?.user?.id || entryData?.teacher?.id) && (entryData?.user?.id == user?.id || entryData?.teacher?.id == user?.id) )){
    return(
      <div className="draftDiv">
        <Chip.Group position="center" value={returnType()} onChange={(e) => handleEdit(e)}>
          <Chip color="yellow" size="lg" radius="xs" value="private">Private</Chip>
          <Chip color="blue" size="lg" radius="xs" value="unlisted">Unlisted</Chip>
          <Chip color="green" size="lg" radius="xs" value="public">Public</Chip>
        </Chip.Group>
      </div>
    )
  }
}