import styled from "styled-components";

export const Experience = ({ props }) => {

  const { experienceData, isTaskool } = props;

  let texts = isTaskool ?
  {
    header: "Təcrübə",
    bSend: "Göndər",
    bEdit: "Redaktə et",
    bDelete: "Sil",
    bCancel: "Ləğv et",
    l1: "İş mövqeyi",
    l2: "Şirkət",
    l3: "İş statusu",
    l4: "Başlama tarixi",
    l5: "Hazırki iş yerim",
    l6: "Bitmə tarixi",
    p1: "Proqramçı",
    p2: "Taskool",
    p3: "Full-time",
    present: "İndi",
    nt1: "Təcrübə əlavə edildi",
    nt2: "Təcrübə redaktə edildi",
    nt3: "Təcrübə silindi",
    ntm1: "Təcrübə uğurla əlavə edildi",
    ntm2: "Təcrübə uğurla redaktə edildi",
    ntm3: "Təcrübə uğurla silindi",
    e1: "Təcrübə əlavə edilərkən xəta baş verdi",
    e2: "Zəhmət olmasa tələb olunan xanaları doldurun",
    e3: "Hal-hazırda işə getmirsinizsə, bitmə tarixini doldurmalısınız",
  }
  :
  {
    header: "Experience",
    bSend: "Submit",
    bEdit: "Edit",
    bDelete: "Remove",
    bCancel: "Cancel",
    l1: "Job title",
    l2: "Company",
    l3: "Job status",
    l4: "Start date",
    l5: "Currently work place",
    l6: "End date",
    p1: "Programmer",
    p2: "Taskool",
    p3: "Full-time",
    present: "Present",
    nt1: "Experience added",
    nt2: "Experience edited",
    nt3: "Experience deleted",
    ntm1: "Experience successfully added",
    ntm2: "Experience successfully edited",
    ntm3: "Experience successfully deleted",
    e1: "An error occurred while adding experience",
    e2: "Please fill in the required fields",
    e3: "If you are not currently working, please fill in the end date",
  }

  return (
    <ExperienceStyle>
      <div className="wrapperExperience">
        <div className="experience">

          <div className="title">
            <h2>{texts?.header}</h2>
          </div>

          <form onSubmit={e => e.preventDefault()}>
            {
              experienceData?.map((experience, index) => {
                return (
                  <div key={index} id={experience?.id} className="insideWrapper">
                    <div className="box">
                      <div className="info">
                        <h3>{experience?.job_position}</h3>
                        <p>{experience?.company}, {experience?.job_status}</p>
                        <ins>{experience?.is_present === true ? experience?.start_date + ` - ${texts?.present}` : experience?.start_date + " - " + experience?.end_date}</ins>
                      </div>
                    </div>
                    <hr />
                  </div>
                )
              })
            }
          </form>
        </div>
      </div>
    </ExperienceStyle>
  );
};

const ExperienceStyle = styled.div`
  display: flex;
  padding-top: 2rem;
  button{
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  .wrapperExperience{
    width: 92%;
    margin: 0rem auto 0 auto;
    h2,h3{
      font-weight: 500;
    }
    .experience{
      margin-bottom: 2rem;
      .insideWrapper{
        margin-top: 1rem;
        hr{
          border: none;
          border-bottom: 1px solid #ddd;
          margin: 1rem;
        }
      }
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addItem{
          background: none;
          font-size: 17px;
          color: #2f80ed;
          font-weight: 400;
          border: none;
          cursor: pointer;
        }
        svg{
          color: #000;
        }
      }
      .box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;
        margin-top: 1rem;
        .manageEntry{
          display: flex;
          margin-right: 2rem;
          button{
            display: flex;
            border: none;
            font-weight: 500;
          }
        }
        .info{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          h3{
            margin: 0;
            font-size: 17px;
          }
          p{
            margin: .5rem 0;
          }
          ins{
            margin: 0;
            text-decoration: none;
            font-size: 14px;
            color: #666;
          }
        }
      }
      .editing{
        padding: 1rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        align-items: center;
        .buttons{
          .groupOne{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
          }
          button{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 0 0 0;
    .wrapperExperience{
      width: 100%;
      padding: .5rem .5rem .5rem .5rem;
      padding: .5rem 0rem .5rem 0rem;
      margin: -1rem auto 0 auto;
      .experience{
        .title{
          width: 92%;
          margin: 0 auto;
        }
        .box{
          .manageEntry{
            display: flex;
            margin-right: .5rem;
          }
        }
        .editing{
          margin: 0 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 335px) {
    .wrapperExperience{
      .experience{
        .box{
          display: block;
          .manageEntry{
          }
        }
      }
    }
  }
`