import { useState } from "react";
import { Modal, TextInput, Input, Select, Button } from "@mantine/core";
import {
  ArrowDown,
  ArrowUp,
  Checkbox as CheckBoxIcon,
  Edit,
  FileText,
  Maximize,
  Notes,
  Photo,
  SquareX,
  VideoPlus,
} from "tabler-icons-react";
import styled from "styled-components";
import axios from "axios";
import parse from "html-react-parser";
import RichTextEditor from "@mantine/rte";

export const FetchedContent = ({ props }) => {
  const [editItem, setEditItem] = useState(false);
  const [quizShown, setQuizShown] = useState(false);
  const [quizData, setQuizData] = useState({});

  const {
    links,
    setLoaderVisible,
    fetchSection,
    sectionContentFiltered,
    handleVideo,
  } = props;

  async function removeItem(type, id, section) {
    setLoaderVisible(true);
    if (type !== "quiz") {
      try {
        const response = await axios.delete(
          `${links.sectionDashed}${type}/${id}/`
        );
        if (response.status === 204) {
          await fetchSection(section.id);
        }
      } catch (error) {
        setLoaderVisible(false);
      }
    } else {
      try {
        const response = await axios.delete(`/quiz/${id}/`);
        if (response.status === 200) {
          await fetchSection(section.id);
        }
      } catch (error) {
        setLoaderVisible(false);
      }
    }
  }

  async function finishEditing(e, type, file, id, section) {
    e.preventDefault();
    setLoaderVisible(true);

    const formX = new FormData();
    formX.append("title", editItem.title);
    formX.append("description", editItem.description);
    editItem.is_question === "false"
      ? formX.append("is_question", false)
      : formX.append("is_question", true);

    if (type === "chart") {
      const fileX = document.getElementById("changeFileChart");
      fileX.files[0] !== undefined && formX.append("file", fileX.files[0]);
    }

    if (type === "video") {
      if (file?.name) {
        const uploadedVideo = await handleVideo(file);

        let returnedLink = uploadedVideo.options.uploadUrl;

        if (returnedLink) {
          formX.append("file", returnedLink);
        }
      }
    }

    async function patchX(type) {
      try {
        const response = await axios.patch(
          `${links.sectionDashed}${type}/${id}/`,
          formX
        );
        if (response.status == 200) {
          setEditItem(false);
          await fetchSection(section.id);
          setLoaderVisible(false);
        }
      } catch (error) {
        await fetchSection(section.id);
        setLoaderVisible(false);
      }
    }

    switch (type) {
      case "video":
        patchX("video");
        break;
      case "chart":
        patchX("chart");
        break;
      case "text":
        patchX("text");
        break;
      default:
        break;
    }
  }

  async function openQuiz(e) {
    setQuizShown(e);
    const response = await axios.get(`/quiz/${e.id}`);
    setQuizData(response?.data);
  }

  function returnLetter(i) {
    let letter = "A";
    for (let j = 0; j < i; j++) {
      letter = String.fromCharCode(letter.charCodeAt(0) + 1);
    }
    return letter;
  }

  let numberX = 0;

  function changePosition(pos) {}

  return (
    <FetchedContentWrapper>
      {quizShown && (
        <Modal
          size="50%"
          title={quizShown.name}
          opened={quizShown}
          onClose={() => setQuizShown(false)}
        >
          {quizData.id &&
            quizData?.question_set.map((entry, index) => {
              if (entry.quiz === quizShown.id) {
                numberX = numberX + 1;
              }
              return (
                // entry.quiz === quizShown.id &&
                <div key={index}>
                  <div style={{ margin: "2rem 0" }}>
                    <div>
                      <h3
                        style={{
                          fontWeight: "500",
                          border: "1px solid #aaa",
                          padding: "0.25rem .75rem",
                        }}
                      >
                        {numberX}.
                      </h3>
                      {parse(entry?.name)}
                    </div>
                    {entry?.option_set.map((option, optionIndex) => {
                      return (
                        <div key={optionIndex}>
                          <p
                            style={{
                              margin: "0.5rem 0",
                              borderRadius: "0.25rem",
                              padding: ".25rem .5rem",
                              backgroundColor:
                                option.is_correct === true
                                  ? "var(--meadow)"
                                  : "none",
                            }}
                          >
                            {returnLetter(optionIndex)}
                            {")"} {option.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {index !== quizData.length - 1 && (
                    <hr
                      style={{
                        margin: "0rem 0",
                        border: "none",
                        borderBottom: "1px solid #aaa",
                      }}
                    />
                  )}
                </div>
              );
            })}
        </Modal>
      )}
      {sectionContentFiltered.map((e, index) => {
        const {
          id,
          name,
          title,
          description,
          file,
          section,
          type,
          is_question,
        } = e;
        return editItem.id === id ? (
          <div key={index} className="entry">
            <form
              onSubmit={(e) =>
                finishEditing(e, type, file, id, section, is_question)
              }
            >
              {/* Entry header */}
              <div className="entryHeader">
                <div className="editButtons">
                  <button className="finishSection" type="submit">
                    <CheckBoxIcon color="#fff" />
                    Finish
                  </button>
                  <button
                    className="cancelEditing"
                    onClick={() => setEditItem(false)}
                  >
                    <SquareX color="#fff" />
                    Cancel
                  </button>
                </div>
                {type == "video" ? (
                  <p>
                    <VideoPlus color="#687385" /> Video
                  </p>
                ) : type == "chart" ? (
                  <p>
                    <Photo color="#687385" /> Chart
                  </p>
                ) : (
                  <p>
                    <FileText color="#687385" /> Text
                  </p>
                )}
                <button>
                  <SquareX
                    color="#687385"
                    size={30}
                    onClick={() => removeItem(type, id, section)}
                  />
                </button>
              </div>

              {/* Inputs */}
              <div className="inputs">
                {/* Title */}
                <TextInput
                  id="title"
                  label="Title"
                  value={editItem.title}
                  onChange={(e) =>
                    setEditItem({ ...editItem, title: e.target.value })
                  }
                  required
                />
                <br />

                {/* Description */}
                <Input.Wrapper label="Description" required name="description">
                  <RichTextEditor
                    name="description"
                    value={editItem.description}
                    onChange={(e) =>
                      setEditItem({ ...editItem, description: e })
                    }
                  />
                </Input.Wrapper>
                <br />

                {/* File */}
                {type !== "text" && (
                  <div>
                    {type == "chart" ? (
                      <div className="fetchedEditWrapper">
                        <div>
                          <p>Replace:</p>
                          <input
                            type="file"
                            id="changeFileChart"
                            name="file"
                            accept="image/*"
                            onChange={(e) =>
                              setEditItem({
                                ...editItem,
                                file: e.target.files[0],
                              })
                            }
                          />
                        </div>
                        <img
                          src={file}
                          alt="preview"
                          style={{ width: "240px" }}
                        />
                      </div>
                    ) : (
                      <div className="fetchedEditWrapper">
                        <div>
                          <p>Replace:</p>
                          <input
                            type="file"
                            name="video"
                            id="changeFileVideo"
                            onChange={(e) =>
                              setEditItem({
                                ...editItem,
                                file: e.target.files[0],
                              })
                            }
                          />
                        </div>
                        <iframe
                          title="video"
                          className="videoFrame"
                          width="260px"
                          src={file}
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                        />
                      </div>
                    )}
                    <br />
                  </div>
                )}

                {/* isQuestion */}
                <Select
                  required
                  id="isQuestion"
                  label="Is this a question?"
                  value={
                    editItem.is_question === "false"
                      ? "false"
                      : editItem.is_question === false
                      ? "false"
                      : "true"
                  }
                  onChange={(e) => setEditItem({ ...editItem, is_question: e })}
                  data={[
                    { value: "true", label: "Yes" },
                    { value: "false", label: "No" },
                  ]}
                />
              </div>
            </form>
          </div>
        ) : (
          <div key={index}>
            {type === "quiz" ? (
              <div className="entry">
                <div className="entryHeader">
                  <button className="editSection" onClick={() => openQuiz(e)}>
                    <Maximize color="#687385" />
                    Open
                  </button>
                  <p>
                    <Notes color="#687385" /> Quiz
                  </p>
                  <button>
                    <SquareX
                      color="#687385"
                      size={30}
                      onClick={() => removeItem(type, id, section)}
                    />
                  </button>
                </div>

                <div className="entryContent">
                  <h3 style={{ marginBottom: "0" }}>
                    <ins style={{ textDecoration: "none", fontWeight: "500" }}>
                      Title:
                    </ins>{" "}
                    {name}
                  </h3>
                </div>
              </div>
            ) : (
              <div className="entry">
                <div className="entryHeader">
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <button
                      className="editSection"
                      onClick={() => setEditItem(e)}
                    >
                      <Edit color="#687385" />
                      Edit
                    </button>
                    <div
                      style={{
                        display: "flex",
                        gap: ".5rem",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <Button
                        onClick={() => changePosition("up")}
                        m={0}
                        p={0}
                        leftIcon={<ArrowUp color="#555" />}
                      />
                      <Button
                        onClick={() => changePosition("up")}
                        m={0}
                        p={0}
                        leftIcon={<ArrowDown color="#555" />}
                      />
                    </div>
                  </div>
                  {type == "video" ? (
                    <p>
                      <VideoPlus color="#687385" /> Video
                    </p>
                  ) : type == "chart" ? (
                    <p>
                      <Photo color="#687385" /> Chart
                    </p>
                  ) : (
                    <p>
                      <FileText color="#687385" /> Text
                    </p>
                  )}
                  <button>
                    <SquareX
                      color="#687385"
                      size={30}
                      onClick={() => removeItem(type, id, section)}
                    />
                  </button>
                </div>

                <div className="entryContent">
                  {/* Title */}
                  <label>Title</label>
                  <p>{title}</p>
                  <hr />

                  {/* Description */}
                  <label>Description</label>
                  <div className="description">{parse(description)}</div>
                  <hr />

                  {/* File */}
                  {type != "text" && (
                    <div>
                      {type == "chart" &&
                      (file.endsWith(".jpg") ||
                        file.endsWith(".png") ||
                        file.endsWith(".jpeg") ||
                        file.endsWith(".webp")) ? (
                        <img
                          src={file}
                          alt="chart"
                          style={{ height: "169px" }}
                        />
                      ) : type == "chart" ? (
                        <a
                          style={{ color: "#1e67bb" }}
                          rel="noreferrer"
                          target="_blank"
                          href={file}
                        >
                          {title} (Download)
                        </a>
                      ) : (
                        <iframe
                          title="video2"
                          className="videoFrame"
                          height="180px"
                          src={file}
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                        />
                      )}
                      <hr />
                    </div>
                  )}

                  {/* Is question */}
                  <div>
                    <p>Is this a question?: {is_question ? "Yes" : "No"}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </FetchedContentWrapper>
  );
};

const FetchedContentWrapper = styled.div`
  .fetchedEditWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    width: 100%;
  }
`;
