import React, { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Select,
  Button,
  Loader,
  MultiSelect,
  Text,
  Container,
} from "@mantine/core";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ModuleExplorer = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [courseOrders, setCourseOrders] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const { data: allCourses, isLoading: isLoadingCourses } = useQuery(
    ["all courses"],
    async () => await axios.get(`api/all-courses`).then((res) => res.data)
  );

  const { data: allUsers, isLoading: isLoadingUsers } = useQuery(
    ["all users"],
    async () => await axios.get(`user/all_users_list`).then((res) => res.data)
  );

  const { data: allSchools, isLoading: isLoadingSchools } = useQuery(
    ["all schools"],
    async () => await axios.get(`school/school_list`).then((res) => res.data)
  );

  const { data: sectionDetails, isLoading: isLoadingSections } = useQuery(
    ["section details", selectedCourse?.id],
    async () =>
      await axios
        .get(`api/section-detail/${selectedCourse?.id}`)
        .then((res) => res.data),
    {
      enabled: !!selectedCourse?.id,
    }
  );

  const resetForm = () => {
    setSelectedUser(null);
    setSelectedCourse(null);
    setSelectedSections([]);
    setSelectedSchool(null);
  };

  const createCourseOrderMutation = useMutation(
    async () => {
      if (selectedUser && selectedCourse) {
        const formX = new FormData();
        formX.append("course", selectedCourse.id);
        formX.append("installment_paid", true);
        formX.append("successfuly_paid", true);
        formX.append("user", selectedUser.id);
        formX.append("school", selectedSchool.id);
        selectedSections.forEach((sectionId) => {
          formX.append(`sections`, sectionId);
        });

        try {
          const response = await axios.post("/api/create_course_order/", formX);
          toast.success("Order successfully created!");

          setCourseOrders((prevOrders) => [
            ...prevOrders,
            {
              user: selectedUser,
              course: selectedCourse,
              sections: selectedSections.map((sectionId) =>
                sectionDetails.find((section) => section.id === sectionId)
              ),
            },
          ]);

          setTimeout(() => {
            setRefreshKey((prevKey) => prevKey + 1);
          }, 3000);
        } catch (error) {
          toast.error("Something went wrong.");
        }
      }
    },
    {
      onSuccess: () => {
        resetForm();
      },
      onError: () => {},
    }
  );

  const handleUserChange = (value) => {
    const user = allUsers.find((user) => user.id === value);
    setSelectedUser(user);
  };

  const handleCourseChange = (value) => {
    const course = allCourses.find((course) => course.id === value);
    setSelectedCourse(course);
  };

  const handleSchoolChange = (value) => {
    const school = allSchools.find((school) => school.id === value);
    setSelectedSchool(school);
  };

  const handleSectionsChange = (values) => {
    setSelectedSections(values);
  };

  const handleCreateOrder = async () => {
    createCourseOrderMutation.mutate();
  };

  return (
    <Container size="md">
      <div key={refreshKey}>
        <div>
          <Toaster position="top-right" />
        </div>
        <h1 style={{ textAlign: "center" }}>Module Management System</h1>
        <div style={{ marginBottom: "16px" }}>
          <label htmlFor="user-select">Select or search for a user:</label>
          <Select
            id="user-select"
            placeholder="Select or search for a user"
            searchable
            clearable
            loading={isLoadingUsers}
            data={
              allUsers?.map((user) => ({
                value: user.id,
                label: user.email,
              })) ?? []
            }
            onChange={(value) => handleUserChange(value)}
            required
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <label htmlFor="school-select">Select school:</label>
          <Select
            id="school-select"
            placeholder="Select school"
            clearable
            searchable
            loading={isLoadingSchools}
            data={
              allSchools?.map((school) => ({
                value: school.id,
                label: school.title,
              })) ?? []
            }
            onChange={(value) => handleSchoolChange(value)}
            required
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <label htmlFor="course-select">Select course:</label>
          <Select
            id="course-select"
            placeholder="Select course"
            clearable
            searchable
            loading={isLoadingCourses}
            data={
              allCourses?.map((course) => ({
                value: course.id,
                label: course.title,
              })) ?? []
            }
            onChange={(value) => handleCourseChange(value)}
            required
          />
        </div>

        <label htmlFor="sections-select">Select sections:</label>
        <MultiSelect
          id="sections-select"
          placeholder="Select sections"
          clearable
          disabled={!selectedCourse}
          loading={isLoadingSections}
          data={
            sectionDetails?.map((section) => ({
              value: section.id,
              label: section.title,
            })) ?? []
          }
          onChange={(values) => handleSectionsChange(values)}
        />

        <Button
          onClick={handleCreateOrder}
          fontWeight={500}
          mt={10}
          disabled={
            createCourseOrderMutation.isLoading ||
            !selectedUser ||
            !selectedCourse
          }
          style={{ width: "100%" }}
        >
          {createCourseOrderMutation.isLoading ? (
            <Loader />
          ) : (
            "Create Course Order"
          )}
        </Button>

        {courseOrders.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <h2 style={{ textAlign: "center" }}>Course Orders:</h2>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {courseOrders.map((order, index) => (
                <li key={index} style={{ marginBottom: "10px" }}>
                  Course ordered for {order.user?.name} ({order.user?.email}),
                  Course: {order.course?.title}, Sections:{" "}
                  {order.sections.map((section, i) => (
                    <span key={section.id}>
                      {section.title}
                      {i < order.sections.length - 1 && ", "}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ModuleExplorer;
