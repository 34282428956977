import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import axios from "axios";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { NotificationsProvider } from "@mantine/notifications";
import { SkeletonLoader } from "../Loaders/SkeletonLoader";
import { ProgramInfo } from "../ProgramInfo/ProgramInfo";
import { ManageProgram } from "../ProgramInfo/ManageProgram";
import { motion } from "framer-motion";
import { AnimateOpa } from "../AnimateOpa";
import { ProgramSyllabus } from "./Syllabus/_Syllabus";

export const Program = ({ props }) => {
  const { type } = props;

  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [entryData, setEntryData] = useState([]);

  window.scrollTo(0, 0, "smooth");

  let link =
    type === "course"
      ? `/api/course/${id}/`
      : type === "internship"
      ? `/internship/${id}/`
      : type === "hackathon" && `/hackathon/hackathonbyid/${id}/`;

  useQuery([`${type}`, id], async () => await axios.get(link), {
    onSuccess: (data) => {
      setEntryData(type === "internship" ? data.data : data.data[0]);
    },
  });

  if (entryData?.is_shared === undefined) {
    return (
      <NotificationsProvider>
        <LoadContent>
          <div className="loadWrap">
            <div className="loadX">
              {[...Array(3)].map((e, i) => {
                return (
                  <motion.div
                    key={i + "y"}
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i}
                      width="100%"
                      height={100}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
            <div className="loadX">
              {[...Array(4)].map((e, i) => {
                return (
                  <motion.div
                    key={i + "x"}
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i + "x"}
                      width="100%"
                      height={140}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
          </div>
        </LoadContent>
      </NotificationsProvider>
    );
  }

  if (entryData?.is_shared !== undefined) {
    return (
      <AnimateOpa>
        <NotificationsProvider>
          <div
            style={{
              borderTop: "1px solid #eee",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              width: "100%",
            }}
          >
            <ManageProgram props={{ user, type, entryData, setEntryData }} />
            <ProgramInfo props={{ user, type, entryData }} />
            <ProgramSyllabus props={{ user, type, entryData }} />
          </div>
        </NotificationsProvider>
      </AnimateOpa>
    );
  }
};

const LoadContent = styled.div`
  border-top: 1px solid #eee;
  .loadWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    justify-content: center;
    width: 90%;
    padding: 3rem 0;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .loadWrap {
      grid-template-columns: 1fr;
      grid-gap: 0rem;
      padding: 2rem 0;
    }
  }
`;
