import { useState } from "react"
import { Button, Text } from "@mantine/core"
import { GitCompare, ShoppingCart, Trash } from "tabler-icons-react"
import { UserFlyer } from "./UserFlyer"
import { WishFlyer } from "./WishFlyer"

export const Wishlist = ({ props }) => {

  const { wishlistedUsers, setWishlistedUsers } = props
  const [ wishlistSelection, setWishlistSelection ] = useState([])

  return (
    <div>
      <div className="header">
        <div className="selected">
          <div className="selectOrNot">
            <Text size={20} weight={600}>Wished accounts: {wishlistSelection?.length}</Text>
            <Button disabled={wishlistSelection.length === 0} onClick={() => setWishlistSelection([])} color={"gray.6"}>Deselect all</Button>
          </div>
          <div className="buttonsManageSelected">
            <Button disabled={wishlistedUsers.length === 0} color={"teal"} leftIcon={<ShoppingCart/>}>Purchase all: {wishlistedUsers?.length}</Button>
            <Button disabled={wishlistSelection.length === 0} color={"teal"} leftIcon={<ShoppingCart/>}>Purchase selected: {wishlistSelection?.length}</Button>
            <Button disabled={wishlistSelection.length < 2} color={"gray.7"} leftIcon={<GitCompare/>}>Compare skills</Button>
            <Button disabled={wishlistSelection.length === 0} color={"red.8"} leftIcon={<Trash/>}>Remove selected</Button>
          </div>
        </div>

        {/* <div className="selectionFilter">
          <Text size={18} weight={600} className="identificationTitle">Filter</Text>
          <div className="boxes">
            <Checkbox color={"gray"} label="Wishlisted hidden" size="md"/>
            <Checkbox color={"gray"} label="Purchased hidden" size="md"/>
          </div>
        </div> */}
      </div>

      <div className="users">
        <div className="reportData">

          <div className="reportTable">

            <div className="thead">
              <Text size={18} weight={600} className="identificationTitle">User profile</Text>
              <Text size={18} weight={600}>University</Text>
              <Text size={18} weight={600}>Location</Text>
              <Text size={18} weight={600}>Contact Info</Text>
            </div>

            <div className="tbody">
              {
                wishlistedUsers && wishlistedUsers?.map((data, index) => <WishFlyer key={data?.id + "w"} props={{ data, index, wishlistSelection, setWishlistSelection }}/>)
              }
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}
