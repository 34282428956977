import styled from "styled-components";
import { Skills } from "./Skills";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Certifications } from "./Certificates";
import { Tasks } from "./Tasks";
import { ProfileIntro } from "./ProfileIntro";
import { NotificationsProvider } from '@mantine/notifications';
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom";
import axios from "axios";

const StudentProfile = ({ props }) => {

  const { id:profileId } = useParams();

  const { isTaskool } = props;

  const { data } = useQuery(["user", profileId],
    async () => { return await axios.get(`/public-user/${profileId}/`) }
  )

  return (
    <NotificationsProvider>
      <ProfileStyle>
        <div className="wrapperAll">
          <div className="profileParts">
            <ProfileIntro props={{ userData: data?.data?.User[0] }}/>
            <Tasks props={{ isTaskool, user:data?.data?.User[0] }}/>
            <Skills props={{ skillsData: data?.data?.UserSkill, isTaskool }}/>
            <ExperienceAndEducation props={{ experienceData: data?.data?.Experience, educationData: data?.data?.Education, isTaskool }}/>
            <Certifications props={{ certificationData: data?.data?.UserCertificate, isTaskool }}/>
          </div>
        </div>
      </ProfileStyle>
    </NotificationsProvider>
  );
};

export default StudentProfile;

const ProfileStyle = styled.div`
  .wrapperAll {
    .profileParts{
      display: flex;
      flex-direction: column;
      grid-gap: .5rem;
    }
  }
  @media (max-width: 768px) {
    .wrapperAll {
      .profileParts{
        display: flex;
        flex-direction: column;
        grid-gap: .5rem;
      }
    }
  }
`;

const ExperienceAndEducation = ({ props }) => {

  const { experienceData, educationData, isTaskool } = props;

  return (
    <E2Style>
      <Experience props={{ experienceData, isTaskool }}/>
      <Education props={{ educationData, isTaskool }}/>
    </E2Style>
  );
};

const E2Style = styled.div`
  background: #fff;
  border-radius: .75rem;
  border: 1px solid #DEE2E6;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`