import React from "react";
import { Input, Select, TextInput } from "@mantine/core";
import { File, FileText, SquareX, VideoPlus } from "tabler-icons-react";
import { RichTextEditor } from "@mantine/rte";
import styled from "styled-components";

export const PendingContent = ({ props }) => {
  const { pendingContent, setPendingContent } = props;

  const removePending = (id) => {
    // remove the item but keep the others from the pendingContent list
    setPendingContent(pendingContent.filter((item) => item.id !== id));
  };

  return (
    <PendingContentWrapper>
      {pendingContent?.map((e, index) => {
        const { id, title, description, file, type, is_question } = e;
        console.log(e);
        return (
          <div key={index} className="entry">
            <div className="entryHeader">
              {type == "video" ? (
                <p>
                  <VideoPlus color="#687385" /> Video
                </p>
              ) : type == "chart" ? (
                <p>
                  <File color="#687385" /> File
                </p>
              ) : (
                <p>
                  <FileText color="#687385" /> Text
                </p>
              )}
              <button className="removePendingItem">
                <SquareX
                  color="#687385"
                  size={30}
                  onClick={() => removePending(id)}
                />
              </button>
            </div>

            <div className="inputs">
              <TextInput
                error={title?.length > 500}
                label={`Title (${title?.length}/500)`}
                required
                value={title}
                name="title"
                onChange={(e) =>
                  setPendingContent([
                    ...pendingContent.slice(0, index),
                    { ...pendingContent[index], title: e.target.value },
                    ...pendingContent.slice(index + 1),
                  ])
                }
              />
              <br />

              <Input.Wrapper label="Description" required>
                <RichTextEditor
                  name="description"
                  value={description}
                  onChange={(e) =>
                    setPendingContent([
                      ...pendingContent.slice(0, index),
                      { ...pendingContent[index], description: e },
                      ...pendingContent.slice(index + 1),
                    ])
                  }
                />
              </Input.Wrapper>
              <br />

              {type !== "text" && type == "chart" && (
                <Input.Wrapper label="File" required>
                  <div className="pendingVideoWrapper">
                    <div>
                      <p>{file?.name}</p>
                      <input
                        type="file"
                        name="file"
                        onChange={(e) =>
                          setPendingContent([
                            ...pendingContent.slice(0, index),
                            {
                              ...pendingContent[index],
                              file: e.target.files[0],
                            },
                            ...pendingContent.slice(index + 1),
                          ])
                        }
                      />
                    </div>
                  </div>
                  <br />
                </Input.Wrapper>
              )}
              {type !== "text" && type == "video" && (
                <Input.Wrapper label="Video" required>
                  <div className="pendingVideoWrapper">
                    <div>
                      <p>{file?.name}</p>
                      <input
                        type="file"
                        name="file"
                        accept="video/*"
                        onChange={(e) =>
                          setPendingContent([
                            ...pendingContent.slice(0, index),
                            {
                              ...pendingContent[index],
                              file: e.target.files[0],
                            },
                            ...pendingContent.slice(index + 1),
                          ])
                        }
                      />
                    </div>
                    {file && (
                      <div>
                        <video width="240px" controls>
                          <source
                            src={URL.createObjectURL(file)}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    )}
                  </div>
                  <br />
                </Input.Wrapper>
              )}

              <Select
                required
                id="isQuestion"
                label="Is this a question?"
                value={is_question === "true" ? "true" : "false"}
                onChange={(e) =>
                  setPendingContent([
                    ...pendingContent.slice(0, index),
                    {
                      ...pendingContent[index],
                      is_question: e === "true" ? "true" : "false",
                    },
                    ...pendingContent.slice(index + 1),
                  ])
                }
                data={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
              />
            </div>
          </div>
        );
      })}
    </PendingContentWrapper>
  );
};

const PendingContentWrapper = styled.div`
  .pendingVideoWrapper,
  .pendingImageWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
  }
`;
